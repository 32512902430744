import { of, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const _router: Router = inject(Router);
    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is authenticated...
            if ( authenticated )
            {
                // Redirect to the root
                _router.navigate(['']);

                // Prevent the access
                return of(false);
            }

            // Allow the access
            return of(true);
        }),
    );
};