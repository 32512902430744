import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Inject, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Subject, takeUntil } from 'rxjs';
import { ChatService, ChatRoom, ChatMember, ChatMessage } from '@kedi/core/chat';
import { SessionService } from '@kedi/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FuseDrawerComponent } from '../../../components/drawer/drawer.component';
import { FuseScrollbarDirective } from '../../../directives/scrollbar/scrollbar.directive';
import { UserComponent } from '../user/user.component';
import { TextFieldModule } from '@angular/cdk/text-field';

@Component({
    selector     : 'quick-chat',
    templateUrl  : './quick-chat.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'quickChat',
    standalone: true,
    imports      : [ NgIf, NgFor, NgClass, NgTemplateOutlet, RouterModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, TextFieldModule, FuseDrawerComponent, FuseScrollbarDirective, UserComponent, MatExpansionModule, MatSidenavModule, DatePipe ],
    providers: [ DatePipe ]
})
export class QuickChatComponent implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('messageInput') messageInput: ElementRef;
    activeMember: ChatMember;
    room: ChatRoom;
    rooms: ChatRoom[] = [];
    opened: boolean = false;
    selectedRoom: ChatRoom;
    private _mutationObserver: MutationObserver;
    contacts: ChatMember[] = [];
    selectedContact: ChatMember = null;
    filteredRooms: ChatRoom[];
    private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
    private _overlay: HTMLElement;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    drawerOpened: boolean = false;
    drawerType: string = "";

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _elementRef: ElementRef,
        private _renderer2: Renderer2,
        private _ngZone: NgZone,
        private _chatService: ChatService,
        private _scrollStrategyOptions: ScrollStrategyOptions,
        public session: SessionService,
        private _cdr: ChangeDetectorRef
    )
    {
        
    }
    get profileName() {
        return this.session.User.FirstName + " " + this.session.User.MiddleName + " " + this.session.User.LastName;
    }

    @HostBinding('class') get classList(): any
    {
        return {
            'quick-chat-opened': this.opened
        };
    }

    @HostListener('input')
    @HostListener('ngModelChange')
    private _resizeMessageInput(): void
    {
        this._ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.messageInput) {
                    this.messageInput.nativeElement.style.height = 'auto';
                    this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;
                }
            });
        });
    }

    ngOnInit(): void
    {
        this._chatService.activeMember$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((_activeMember: ChatMember) => {
            this.activeMember = _activeMember;
        });        

        this._chatService.contacts$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((contacts: ChatMember[]) => {
            this.contacts = contacts ?? [];
        });        
        this._chatService.room$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((room: ChatRoom) => {
                this.room = room;
            });
        this._chatService.rooms$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((rooms: ChatRoom[]) => {
                this.rooms = rooms ?? [];
            });
        this._chatService.room$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((room: ChatRoom) => {
                this.selectedRoom = room;
            });
        this._chatService.messageReceived.subscribe((message: ChatMessage) => {
            this._cdr.markForCheck();
        })
    }

    ngAfterViewInit(): void
    {
        this._mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const mutationTarget = mutation.target as HTMLElement;
                if ( mutation.attributeName === 'class' )
                {
                    if ( mutationTarget.classList.contains('cdk-global-scrollblock') )
                    {
                        const top = parseInt(mutationTarget.style.top, 10);
                        this._renderer2.setStyle(this._elementRef.nativeElement, 'margin-top', `${Math.abs(top)}px`);
                    }
                    else
                    {
                        this._renderer2.setStyle(this._elementRef.nativeElement, 'margin-top', null);
                    }
                }
            });
        });
        this._mutationObserver.observe(this._document.documentElement, {
            attributes     : true,
            attributeFilter: ['class']
        });
    }    

    ngOnDestroy(): void
    {
        this._mutationObserver.disconnect();
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    open(): void
    {
        if ( this.opened )
        {
            return;
        }
        this._toggleOpened(true);
    }

    close(): void
    {
        if ( !this.opened )
        {
            return;
        }
        this._toggleOpened(false);
    }

    toggle(): void
    {
        if ( this.opened )
        {
            this.close();
        }
        else
        {
            this.open();
        }
    }

    selectedContactChanged($event) {
        console.log($event);
    }

    selectRoom(id: string): void
    {
        this._toggleOpened(true);
        this._chatService.getRoom(id).subscribe(r => {
            this.selectedRoom = r;
        });
    }

    createPrivateRoom(contact: ChatMember) {
        // let _chat = this.chats.find(c => c.contactId == contact.id);
        // if (!_chat) {
        // }
        // else {
        //     this.drawerType = "";
        //     this.drawerOpened = false;
        //     this._cdr.markForCheck();
        //     this.selectRoom(_chat.id);
        // }
        this._chatService.createPrivateRoom(contact).subscribe((result: ChatRoom) => {
            console.log(result);
        });

        this.drawerType = "";
        this.drawerOpened = false;
        this._cdr.markForCheck();
    }

    messageInputKeyPress($event) {
        var key = $event.keyCode;
        if (key === 13) {
            this.sendMessage(this.messageInput.nativeElement.value);
            this.messageInput.nativeElement.value = "";
            return false;
        }
        else {
            return true;
        }
    }

    sendMessage(message: string) {
        let chatMessage: ChatMessage = {
            body: message,
            id: this.session.Guid(),
            senderId: this.activeMember.id,
            type: "text"
        };

        this._chatService.sendMessage(this.selectedRoom.id, chatMessage).subscribe((message) => {

        });
    }
    openNewRoom() {
        this._chatService.getContacts().subscribe((response) => {
            this.drawerType = "newRoom";
            this.drawerOpened = true;
            this._cdr.markForCheck();
        });
    }

    filterRooms(query: string): void
    {
        if ( !query )
        {
            this.filteredRooms = this.rooms;
            return;
        }

        this.filteredRooms = this.rooms.filter(room => room.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
    }

    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    private _showOverlay(): void
    {
        this._hideOverlay();
        this._overlay = this._renderer2.createElement('div');
        if (!this._overlay) return;
        this._overlay.classList.add('quick-chat-overlay');
        this._renderer2.appendChild(this._elementRef.nativeElement.parentElement, this._overlay);
        this._scrollStrategy.enable();
        this._overlay.addEventListener('click', () => {
            this.close();
        });
    }

    private _hideOverlay(): void
    {
        if (!this._overlay) return;
        if (this._overlay)
        {
            this._overlay.parentNode.removeChild(this._overlay);
            this._overlay = null;
        }
        this._scrollStrategy.disable();
    }

    private _toggleOpened(open: boolean): void
    {
        this.opened = open;
        if (open)
        {
            this._showOverlay();
        }
        else
        {
            this._hideOverlay();
        }
    }

    getRoomAvatar(room: ChatRoom) {
        if (!room.avatar) return null;
        return this.session.getImageUrl(room.avatar);
    }

    isMine(message: ChatMessage) {
        return message && message.senderId == this.activeMember.id;
    }
}
