<!-- Avatar -->
<div *ngIf="profile" class="-mt-26 lg:-mt-22 rounded-full">
    <img *ngIf="user.Avatar"
        class="w-32 h-32 rounded-full ring-4 ring-bg-card object-cover"
        [src]="user.Avatar"
        alt="User avatar">
    <div
        class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
        *ngIf="!user.Avatar">
        {{userLetters}}
    </div>            
</div>
<div *ngIf="!profile && !big" [class]="sizeClass">
    <img
        class="w-full h-full rounded-full object-cover"
        *ngIf="user.Avatar"
        [src]="user.Avatar"
        alt="User avatar">
    <div
        class="flex items-center justify-center w-full h-full rounded-full overflow-hidden uppercase text-7xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
        *ngIf="!user.Avatar">
        {{userLetters}}
    </div>
    <!-- <mat-icon
        class="icon-size-24"
        *ngIf="!user.Avatar"
        [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> -->
</div> 
<div *ngIf="!profile && big && !editMode" class="flex items-center justify-center w-32 h-32 rounded-full overflow-hidden ring-4 ring-bg-card">
    
    <img
        class="object-cover w-full h-full"
        *ngIf="user.Avatar"
        [src]="user.Avatar">
    <div
        class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
        *ngIf="!user.Avatar">
        {{userLetters}}
    </div>
</div>
<!-- Avatar -->
<div *ngIf="!profile && big && editMode" class="flex flex-auto items-end ">
    <div class="relative flex items-center justify-center w-32 h-32 rounded-full overflow-hidden ring-4 ring-bg-card">
        <!-- Upload / Remove avatar -->
        <div class="absolute inset-0 bg-black bg-opacity-40 z-10"></div>
        <div class="absolute inset-0 flex items-center justify-center z-20">
            <div>
                <input
                    id="avatar-file-input"
                    class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                    type="file"
                    [multiple]="false"
                    [accept]="'image/jpeg, image/png'"
                    (change)="uploadAvatar(avatarFileInput.files)"
                    #avatarFileInput>
                <label
                    class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                    for="avatar-file-input"
                    matRipple>
                    <mat-icon
                        class="text-white"
                        [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                </label>
            </div>
            <div>
                <button
                    mat-icon-button
                    (click)="removeAvatar()">
                    <mat-icon
                        class="text-white"
                        [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                </button>
            </div>
        </div>
        <!-- Image/Letter -->
        <img
            class="object-cover w-full h-full"
            *ngIf="user.Avatar"
            [src]="user.Avatar">
        <div
            class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
            *ngIf="!user.Avatar">
            {{userLetters}}
        </div>
    </div>
</div> 
