import { KediEntityBase } from "../../entities/entity.base";

export class TimedTask extends KediEntityBase {
  Code?: string;
  Description?: string;
  AutoStart?: boolean;
  IsActive?: boolean;
  IsRunning?: boolean;
  ValidFrom?: Date;
  ValidTo?: Date;
  PeriodType?: number;
  PeriodDesc?: string[];
  PeriodHour?: number;
  PeriodMinute?: number;
  ActionType?: number;
  ActionParams?: string[];
}
