<!-- Button -->
<button
    mat-icon-button
    [matTooltip]="'Kullanıcı'"
    [matMenuTriggerFor]="userActions"
    (menuOpened)="userActionsMenuOpened()">
    <span class="relative">
        <img
            [class]="'w-' + size + ' h-' + size + ' rounded-full object-cover'"
            *ngIf="showAvatar && user.Avatar"
            [src]="user.Avatar">
        <mat-icon
            *ngIf="!showAvatar || !user.Avatar"
            [svgIcon]="'heroicons_outline:user-circle'"
            [class.text-white]="session.config.site.layoutSettings.TransparentHeader"
            ></mat-icon>
        <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user.Avatar,
                        'bg-green-500': user.Status === 'online',
                        'bg-amber-500': user.Status === 'away',
                        'bg-red-500': user.Status === 'busy',
                        'bg-gray-400': user.Status === 'not-visible'}"
        ></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button *ngIf="user.Email" mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{ "LAYOUT.SIGNED_IN_AS" | transloco }}</span>
            <span class="mt-1.5 text-md font-medium">{{user.Email}}</span>
        </span>
    </button>
    <mat-divider *ngIf="user.UserName" class="my-2"></mat-divider>
    <button *ngIf="user.UserName && siteUserSettings.HasUserProfile" mat-menu-item (click)="goToProfile()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{ "LAYOUT.PROFILE" | transloco }}</span>
    </button>
    <button *ngIf="user.UserName && siteUserSettings.HasUserSettings" mat-menu-item (click)="goToSettings()">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>{{ "LAYOUT.SETTINGS" | transloco }}</span>
    </button>
    <button *ngIf="user.UserName && siteUserSettings.HasUserStatus" mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>{{ "LAYOUT.STATUS" | transloco }}</span>
    </button>
    <mat-divider *ngIf="user.UserName && hasUserAction" class="my-2"></mat-divider>
    <button *ngIf="user.UserName" 
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'logout'"></mat-icon>
        <span>{{ "LAYOUT.SIGN_OUT" | transloco }}</span>
    </button>
    <button *ngIf="!user.UserName" 
        mat-menu-item
        (click)="signIn()">
        <mat-icon [svgIcon]="'login'"></mat-icon>
        <span>{{ "LAYOUT.SIGN_IN" | transloco }}</span>
    </button>
    <button *ngIf="!user.UserName" 
        mat-menu-item
        (click)="signUp()">
        <mat-icon [svgIcon]="'person_add_alt'"></mat-icon>
        <span>{{ "LAYOUT.SIGN_UP" | transloco }}</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>{{ "LAYOUT.ONLINE" | transloco }}</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>{{ "LAYOUT.AWAY" | transloco }}</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>{{ "LAYOUT.BUSY" | transloco }}</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>{{ "LAYOUT.INVISIBLE" | transloco }}</span>
    </button>
</mat-menu>
