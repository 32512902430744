<button
    mat-icon-button
    [matTooltip]="'Aktif Firma'"
    *ngIf="!opened"
    (click)="op.toggle($event)">
    <mat-icon 
        [svgIcon]="'heroicons_outline:briefcase'"
        [class.text-white]="session.config.site.layoutSettings.TransparentHeader"
    ></mat-icon>
</button>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}">
    <ng-template pTemplate>
        <div class="text-lg">
            {{ ActiveFirm?.Code }} {{ ActiveFirm?.Name }}
        </div>
    </ng-template>
</p-overlayPanel>

<!-- General result template -->
<ng-template
    #generalResult
    let-result>
    <div class="flex items-center">
        <div class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
            <img
                *ngIf="result.avatar && !result.avatar.startsWith('icon:')"
                [src]="getImageUrl(result.avatar)">
            <mat-icon
                class="m-0 icon-size-5 text-primary dark:text-primary-400"
                *ngIf="!result.avatar || result.avatar.startsWith('icon:')"
                [svgIcon]="result.avatar.substring(5)"></mat-icon>
        </div>
        <div class="ml-3 truncate">
            <span [innerHTML]="result.name"></span>
        </div>
    </div>
</ng-template>
