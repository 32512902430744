import { InjectionToken } from '@angular/core';

export const FUSE_CONFIG = new InjectionToken<any>('FUSE_APP_CONFIG');

import { Themes } from "./config.types";

export const themes: Themes =[
    {
        id  : 'theme-default',
        name: 'Default',
        baseColor: null
    },
    {
        id  : 'theme-brand',
        name: 'Brand',
        baseColor: null
    },
    {
        id  : 'theme-teal',
        name: 'Teal',
        baseColor: null
    },
    {
        id  : 'theme-rose',
        name: 'Rose',
        baseColor: null
    },
    {
        id  : 'theme-purple',
        name: 'Purple',
        baseColor: null
    },    
    {
        id  : 'theme-amber',
        name: 'Amber',
        baseColor: null
    },
    {
        id  : 'theme-kedi',
        name: 'Kedi Varsayılan',
        baseColor: "#1565c0" 
    },
    {
        id  : 'theme-ashan',
        name: 'Aşhan',
        baseColor: "#ddba7f" 
    },
    {
        id  : 'theme-ihe1',
        name: 'İHE Turuncu',
        baseColor: "#de6f26"
    },
    {
        id  : 'theme-ihe2',
        name: 'İHE Bordo',
        baseColor: '#9A3324'
    }
];

