/*import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, CanMatch, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
*/
import { of, switchMap } from 'rxjs';
import { SessionService } from '../../session/session.service';
import { AuthService } from '../auth.service';
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const _router: Router = inject(Router);
    const _session: SessionService = inject(SessionService);
    if (_session.MustChangePassword == true && state.url.replace(/^\/+|\/+$/g, '') != _session.ChangePasswordRoute.replace(/^\/+|\/+$/g, '')) {
        return _router.navigate([_session.ChangePasswordRoute]);
    }
    const _redirectUrl = state.url === '/sign-out' ? '/' : state.url;

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is not authenticated...
            if ( !authenticated )
            {
                // Redirect to the sign-in page
                _router.navigate(['sign-in'], {queryParams: {_redirectUrl}});

                // Prevent the access
                return of(false);
            }
            let dr = _session.config.site.defaultRoute;
            if (!dr.startsWith('/')) dr = '/'+dr;
            if (dr.endsWith('/')) dr = dr.substring(0,dr.length - 1);
            let split = _redirectUrl.split('?') ?? [''];
            if (_redirectUrl != dr && split.length > 1 && !_session.checkRoute(split[0])) {
                // Redirect to default page
                _router.navigate([dr]);
                return of(false);
            }

            // Allow the access
            if (!_session.checkRoute(_redirectUrl)) {
            return _router.navigate([_session.config.site.defaultRoute]);
            }
            return of(true);
        }),
    );
};

/*

@Injectable({
    providedIn: 'root'
})
export class _AuthGuard implements CanMatch, CanActivate, CanActivateChild, CanLoad
{

    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _session: SessionService
    )
    {
    }

    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        if (this._session.MustChangePassword == true && state.url.replace(/^\/+|\/+$/g, '') != this._session.ChangePasswordRoute.replace(/^\/+|\/+$/g, '')) return this._router.navigate([this._session.ChangePasswordRoute]);
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }
    
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._check('/');
    }

    canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const joinedURL = window.location.href.replace(window.location.origin,""); // `/${segments.join('/')}`;
        const redirectURL = joinedURL === '/sign-out' ? '/' : joinedURL;
        if (this._session.MustChangePassword == true && joinedURL.replace(/^\/+|\/+$/g, '') != this._session.ChangePasswordRoute.replace(/^\/+|\/+$/g, '')) return this._router.navigate([this._session.ChangePasswordRoute]);
        return this._checkMatch(redirectURL);
    }

    private _checkMatch(redirectURL?: string): Observable<boolean> | Promise<boolean> | boolean
    {
        // Check the authentication status
        return this._authService.check().pipe(
            switchMap((authenticated) => {

                // If the user is not authenticated...
                if ( !authenticated )
                {
                    // Redirect to the sign-in page with a redirectUrl param
                    // const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);

                    // return of(urlTree);
                    
                    // Redirect to the sign-in page
                    this._router.navigate(['sign-in'], {queryParams: {redirectURL}});

                    // Prevent the access
                    return of(false);
                }
                let dr = this._session.config.site.defaultRoute;
                if (!dr.startsWith('/')) dr = '/'+dr;
                if (dr.endsWith('/')) dr = dr.substring(0,dr.length - 1);
                let split = redirectURL.split('?') ?? [''];
                if (redirectURL != dr && split.length > 1 && !this._session.checkRoute(split[0])) {
                    // Redirect to default page
                    this._router.navigate([dr]);
                    return of(false);
                }

                // Allow the access
                return of(true);
            })
        );
    }

    private _check(redirectURL: string): Observable<boolean>
    {
        // Check the authentication status
        return this._authService.check()
                   .pipe(
                       switchMap((authenticated) => {

                           // If the user is not authenticated...
                           if ( !authenticated )
                           {
                               // Redirect to the sign-in page
                               this._router.navigate(['sign-in'], {queryParams: {redirectURL}});

                               // Prevent the access
                               return of(false);
                           }
                           let dr = this._session.config.site.defaultRoute;
                           if (!dr.startsWith('/')) dr = '/'+dr;
                           if (dr.endsWith('/')) dr = dr.substring(0,dr.length - 1);
                           let split = redirectURL.split('?') ?? [''];
                           if (redirectURL != dr && split.length > 1 && !this._session.checkRoute(split[0])) {
                                // Redirect to default page
                                this._router.navigate([dr]);
                                return of(false);
                           }

                           // Allow the access
                           if (!this._session.checkRoute(redirectURL)) {
                            return this._router.navigate([this._session.config.site.defaultRoute]);
                           }
                           return of(true);
                       })
                   );
    }
}
*/




