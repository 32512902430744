import { Observable } from "rxjs";
import { IKediDataResult, IKediResponse } from "../api/api.types";

export interface UyumCompany {
  Code: string;
  Name: string;
}

export class VOEAction {
  TypeName?: string;
  Name?: string;
  Description?: string;
  ApiName?: string;
  ControllerName?: string;
  MethodName?: string;
  RequestTypeName?: string;
  RequestUrl?: string;
  RequestJson?: string;
  Params?: VOEKeyValue[];
}

export class VOEAgent {
  PID?: number;
  Port?: number;
  Status?: number;
  StatusName?: string;
  LastCall?: Date;
  LastProcedure?: string;
  LastParams?: string;
}

export class VOEApi {
  Id?: string;
  Name?: string;
  RootNamespace?: string;
  AssemblyName?: string;
  BaseUrl?: string;
  TargetFrameworkVersion?: string;
  InfoTitle?: string;
  InfoDescription?: string;
  SampleToken?: string;
  DefaultApi?: boolean;
  Controllers?: VOEApiController[];
}

export class VOEApiController {
  Api?: VOEApi;
  Id?: string;
  Name?: string;
  ClassName?: string;
  RoutePrefix?: string;
  InfoTitle?: string;
  InfoDescription?: string;
  BrokerNames?: string[];
  Procedures?: VOEApiProcedure[];
  Methods?: VOEApiMethod[];
}

export class VOEApiMethod {
  Controller?: VOEApiController;
  Id?: string;
  Name?: string;
  Route?: string;
  VoeMethod?: string;
  InputType?: string;
  OutputType?: string;
  InfoTitle?: string;
  InfoDescription?: string;
  MethodType?: string;
}

export class VOEApiProcedure {
  BrokerName?: string;
  Name?: string;
  Title?: string;
  MethodType?: string;
}

export interface VOEAppProduct {
  ProductType: string;
  ProductCode: string;
  ProductName: string;
  Version: string;
  LastVersion: string;
}

export interface VOEAppLicense {
  LicenseKey: string;
  Authority: string;
  Edition: string;
  UserCount: number;
  ValidUntil: Date;
}

export interface VOEAppAccount {
  AccountRole: string;
  AccountCode: string;
  AccountKey: string;
  Name: string;
  Phone: string;
  Email: string;
  Address: string;
}

export interface VOEAppInfo {
  Account: VOEAppAccount;
  Product: VOEAppProduct;
  License: VOEAppLicense;
  Settings: VOEAppSettings;
}

export interface VOEAppSettings {
  ProductPath: string;
  DataPath: string;
  Smtp: VOESmtpSettings;
  Value: VOEKeyValue[];
}

export interface VOESmtpSettings {
  Username: string;
  Password: string;
  Host: string;
  Port: string;
  IsSsl: boolean;
}

export class VOEBroker {
  Id?: string;
  Name?: string;
  MinAgentCount?: number;
  MaxAgentCount?: number;
  MinPort?: number;
  MaxPort?: number;
  AutoStart?: boolean;
  DebugMode?: boolean;
  DefaultBroker?: boolean;
  SampleToken?: string;
  OEBinPath?: string;
  OEVersion?: string;
  OEPropath?: string;
  OEWorkDir?: string;
  OEStartupParameters?: string;
  OEProcedureStart?: string;
  OEProcedureStop?: string;
  OEProcedureActivate?: string;
  OEProcedureDeactivate?: string;
  Namespace?: string;
  ProjectFolder?: string;
  ErpType?: number;
  ErpToken?: string;
  ErpUser?: string;
  ErpPassword?: string;
  ErpDomain?: string;
  Status?: string;
  Procedures?: VOEProcedure[];
  Agents?: VOEAgent[];
  CodeFiles?: VOECodeFile[];
}

export class VOECodeFile {
  Name?: string;
  Path?: string;
}

export class VOEDashboard {
  Id?: string;
  Name?: string;
  BrokerName?: string;
  DataSourceType?: string;
  DataSource?: string;
  AskForCompany?:	boolean;
  AskForDate?: boolean;
  Widgets?: VOEWidget[];
}

export class VOEDashboardRequest
{
  Domain: string; 
  StartDate: any;
  EndDate: any;
}

export class VOEDashboardWidget
{
  Name: string;
  Title: string;
  WidgetType: string;
}

export class VOEDashboardView
{
    StartDate: Date;
    EndDate: Date;
    CompanyCode: string;
    Widgets: VOEDashboardWidget[];
}

export class VOEDashboardGridColumn {
  prop: string;
  name: string;
  width: number;
}

export class VOEDashboardGrid extends VOEDashboardWidget
{
  columns: VOEDashboardGridColumn[];
  rows: any[];
}


export class VOEDataSet {
  Id?: number;
  Name?: string;
  Tables?: string[];
  ParentProcedure?: string;
}

export class VOEField
{
  Id?: number;
  Type?: number;
  Name?: string;
  Extent?: number;
  TypeName?: string;             
}

export class VOEKeyValue {
  Key?: string;
  Value?: string;
}

export class VOELog {
  Type?: string;
  Time?: Date;
  Pid?: string;
  Port?: string;
  Log?: string;
}

export class VOELogData {
  Data: any[];
  PageCount?: number;
  PageSize?: number;
  PageNumber?: number;
}

export interface VOELogFilter {
  StartDate: Date;
  EndDate: Date;
  Type: string;
  PageNumber: number;
  PageSize: number;
  PageCount: number;
}

export class VOELogRequest {
  StartDate: Date;
  EndDate: Date;
  Type?: string;
  PageNumber?: number;
  PageSize?: number;
}

export interface VOEOut{
  
}

export class VOEParam {
  Id?: number;
  Type?: number;
  Mode?: number;
  Name?: string;
  Extent?: number;
  TypeName?: string;
  ModeName?: string;
  Signature?: string;
}

export enum VOEParamType
{
    CHARACTER=1,
    DATE=2,
    DECIMAL=3,
    INTEGER=4,
    INT64=5,
    LOGICAL=6,
    DATETIME=7,
    DATETIME_TZ = 8,
    BLOB=9,
    CLOB=10,
    TABLE = 11,
    DATASET = 12,
}

export enum VOEParamMode
{
    INPUT = 1,
    OUTPUT = 2,
    INPUT_OUTPUT = 3
}

export class VOEPeriod {
  Description?: string;
  Since?: Date;
  Until?: Date;
  TypeName?: string;
  Hour?: number;
  Minute?: number;
  Conditions?: VOEPeriodCondition[];
}

export class VOEPeriodCondition {
  BaseName?: string;
  OperatorName?: string;
  Value?: number;
}

export class VOEProcedure {
  Id?: number;
  Name?: string;
  Path?: string;
  Url?: string;
  Timeout?: number;
  NeedsErpCredentials?: boolean;
  Parameters?: VOEParam[];
  Tables?: VOETable[];
  DataSets?: VOEDataSet[];
  ParentBroker?: string;
}

export interface VOEProcTree{
  customColumn : string;
  defaultColumns : string[];
  allColumns : string[];
  data: VOEProcNode<VOEProcData>[];  
}

export interface VOEProcNode<T> {
  data: T;
  children?: VOEProcNode<T>[];
  expanded?: boolean;
}

export interface VOEProcData {
  name: string;
  description: string;
  actions: string;
  part1: string;
  part2: string;
  part3: string;
  class: string;
  super: boolean;
}

export class VOEQuery {
  Id?: string;
  Name?: string;
  Description?: string;
  BrokerName?: string;
  QueryString?: string;
  ResultType?: string;
  QueryParams?: VOEQueryParam[];
  QueryColumns?: VOEQueryColumn[];
}

export class VOEQueryColumn {
  Id?: string;
  Name?: string;
  Caption?: string;
  Width?: number;
  Order?: number;
  Visible?: boolean;
  DataType?: number;
}

export class VOEQueryParam {
  Id?: string
  Name?: string;
  Description?: string;
  DefaultValue?: string;
  DataSource?: string;
  AskIfNeeded?: boolean;
  AskOnBoard?: boolean;
  DataType?: number;
  DataSourceType?: number;
}

export class VOEQueryRequest {
  token?: string;
  queryName?: string;
  resultType?: string;
  params?: VOEQueryRequestParam[];
}

export class VOEQueryRequestParam {
  name?: string;
  value?: string;
}

export class VOEQueryResponse {
  name?: string;
  description?: string;
  tables?: VOEQueryResponseTable[];
}

export class VOEQueryResponseColumn {
  field?: string;
  header?: string;
  width?: number;
  order?: number;
  visible?: boolean;
  datatype?: string;
  hide?: boolean;
  sortable?: boolean;
  filter?: boolean;
  checkboxSelection?: boolean;
  headerName?: string;
  pinned?: string;
}

export class VOEQueryResponseTable {
  name?: string;
  description?: string;
  cols?: VOEQueryResponseColumn[];
  rows?: any[];
}

export class VOEServerSettings {
  BufferSize?: number;
  Verbose?: number;
  Brokers?: VOEBroker[];
  Apis?: VOEApi[];
  Tasks?: VOETask[];
  Dashboards?: VOEDashboard[];
  Queries?: VOEQuery[];
}

export interface VOESessionInfo {
  AppInfo: VOEAppInfo;
  LastLogin: Date;
  Role: string;
  StartedAt: Date;
  Username: string;
  ValidUntil: Date;
  Language: string;
  Domain: string;
  UserGroup: string;
  Token: string;
}

export class VOETable {
  Id?: number;
  Name?: string;
  Fields?: VOEField[];
}

export class VOETask {
  Id?: string;
  Name?: string;
  InfoTitle?: string;
  InfoDescription?: string;
  AutoStart?: boolean;
  Period?: VOEPeriod;
  Action?: VOEAction;
  Status?: number;
}

export interface VOEUser {
  Id?: string;
  Role?: string;
  Username?:string;
  Password?: string;
  UserGroup?: string;
  Domain?: string;
  Language?: string;
  DelayHours?: number;
  IsIntegrationUser?: boolean;
  IntegrationToken?: string;
  ErpUsername?: string;
  ErpPassword?: string;
  CanRunProcedure?: boolean;
  CanRunQuery?: boolean;
  CanRunFreeQuery?: boolean;
  IsAdmin?: boolean;
}

export interface VOEUserGroup {
  Id?: string;
  Name?: string;
  Description?: string;
}

export class VOEWidget {
  Id?: number;
  Name?: string;
  Order?: number;
  Size?: string;
  DataSource?: string;
  WidgetType?: string;
}

export abstract class VOEData {
  abstract sessionInfo: VOESessionInfo;
  abstract reloadServer(): Observable<IKediResponse<boolean>>;
  abstract getSessionInfo(): Observable<IKediResponse<VOESessionInfo>>;
  abstract getBrokers(): Observable<IKediResponse<IKediDataResult<VOEBroker>>>;
  abstract getBroker(broker: VOEBroker): Observable<IKediResponse<VOEBroker>>;
  abstract saveBroker(brokername: string, broker: VOEBroker): Observable<IKediResponse<VOEBroker>>;
  abstract getAgents(broker:VOEBroker): Observable<IKediResponse<VOEAgent[]>>;
  abstract getAgent(broker: VOEBroker, agent: VOEAgent): Observable<IKediResponse<VOEAgent>>;
  abstract startBroker(broker: VOEBroker): Observable<IKediResponse<VOEAgent[]>>;
  abstract deleteBroker(broker: VOEBroker): Observable<IKediResponse<VOEBroker[]>>;
  abstract recreateCode(broker: VOEBroker): Observable<IKediResponse<VOEProcedure[]>>;
  abstract createAssembly(broker: VOEBroker): Observable<any>;
  abstract shutdownBroker(broker: VOEBroker): Observable<IKediResponse<VOEAgent[]>>;
  abstract addAgents(broker:VOEBroker, agentCount: number): Observable<IKediResponse<VOEAgent[]>>;
  abstract removeAgents(broker:VOEBroker, agentCount: number): Observable<IKediResponse<VOEAgent[]>>;
  // abstract showDialog(message:Partial<VOEDialogComponent>): Observable<VOEDialogResult>;
  // abstract showPeriodDialog(message:Partial<VOEPeriodDialogComponent>): Observable<VOEDialogResult>;
  // abstract showActionDialog(message:Partial<VOEActionDialogComponent>): Observable<VOEDialogResult>;
  // abstract showPasswordDialog(message:Partial<VOEPasswordDialogComponent>): Observable<VOEDialogResult>;
  // abstract showError(message:string): void;
  // abstract showWarning(message:string): void;
  // abstract showInfo(message:string): void;
  // abstract askQuestion(message:string): Observable<VOEDialogResult>;
  abstract getSampleController(broker:VOEBroker): Observable<IKediResponse<string>>;
  abstract getSampleModel(broker:VOEBroker): Observable<IKediResponse<string>>;
  abstract getSampleClient(broker:VOEBroker): Observable<IKediResponse<string>>;
  abstract getSampleRequest(broker:VOEBroker, procedure:VOEProcedure): Observable<IKediResponse<string>>;
  abstract getLog(broker:VOEBroker, filter:VOELogFilter): Observable<IKediResponse<VOELogData>>;
  abstract getCodeFiles(broker:VOEBroker): Observable<IKediResponse<VOECodeFile[]>>;
  abstract setProcedures(broker:VOEBroker, codeFiles:VOECodeFile[]): Observable<IKediResponse<VOEProcedure[]>>;
  abstract setUrl(broker:VOEBroker, procedure:VOEProcedure, url:string): Observable<IKediResponse<boolean>>;
  abstract setSampleToken(broker:VOEBroker, token:string): Observable<IKediResponse<boolean>>;
  abstract call(url: string, method: string, token: string, body:string): Observable<IKediResponse<any>>;

  abstract getApis(): Observable<IKediResponse<VOEApi[]>>;
  abstract getApi(api: VOEApi): Observable<IKediResponse<VOEApi>>;
  abstract saveApi(apiname: string, api: VOEApi): Observable<IKediResponse<VOEApi>>;
  abstract deleteApi(api: VOEApi): Observable<IKediResponse<VOEApi[]>>;
  abstract getApiLog(api:VOEApi, filter:VOELogFilter): Observable<IKediResponse<VOELogData>>;
  abstract setControllerProcedures(api:VOEApi, controller:VOEApiController, procedures:VOEApiProcedure[]): Observable<IKediResponse<VOEApiMethod[]>>;
  abstract getDocumentation(apiname: string): void;

  abstract getSampleModelCode(api:VOEApi): Observable<IKediResponse<string>>;
  abstract getSampleControllerCode(api:VOEApi, controller:VOEApiController): Observable<IKediResponse<string>>;
  abstract getSampleTestCode(api:VOEApi, controller:VOEApiController, method:VOEApiMethod): Observable<IKediResponse<string>>;


  abstract getTasks(): Observable<IKediResponse<VOETask[]>>;
  abstract getTask(task: VOETask): Observable<IKediResponse<VOETask>>;
  abstract saveTask(taskname: string, task: VOETask): Observable<IKediResponse<VOETask>>;
  abstract deleteTask(task: VOETask): Observable<IKediResponse<VOETask[]>>;
  abstract startTask(task: VOETask): Observable<IKediResponse<VOETask>>;
  abstract shutdownTask(task: VOETask): Observable<IKediResponse<VOETask>>;
  abstract getTaskLog(task: VOETask,filter:VOELogFilter): Observable<IKediResponse<VOELogData>>;
  abstract getPeriodDescription(period:VOEPeriod): Observable<IKediResponse<string>>;
  abstract getActionDescription(period:VOEAction): Observable<IKediResponse<string>>;

  abstract getQueries(): Observable<IKediResponse<VOEQuery[]>>;
  abstract getQuery(query: VOEQuery): Observable<IKediResponse<VOEQuery>>;
  abstract saveQueries(queryname: string, query: VOEQuery): Observable<IKediResponse<VOEQuery>>;
  abstract runQuery(query: VOEQueryRequest): Observable<IKediResponse<VOEQueryResponse>>;
  abstract runQueryString(brokername:string, query: VOEQueryRequest): Observable<IKediResponse<VOEQueryResponse>>;
  abstract deleteQuery(query: VOEQuery): Observable<IKediResponse<VOEQuery[]>>;
  abstract saveQuery(queryname: string, query: VOEQuery): Observable<IKediResponse<VOEQuery>>;
  abstract saveQueryView(queryname: string, queryview: VOEQueryColumn[]): Observable<IKediResponse<boolean>>;

  abstract getCompanies(brokername: string): Observable<IKediResponse<UyumCompany[]>>;
  abstract getDashboards(): Observable<IKediResponse<VOEDashboard[]>>;
  abstract getDashboard(dashboard: VOEDashboard): Observable<IKediResponse<VOEDashboard>>;
  abstract saveDashboard(dashboardname: string, dashboard: VOEDashboard): Observable<IKediResponse<VOEDashboard>>;
  abstract deleteDashboard(dashboard: VOEDashboard): Observable<IKediResponse<VOEDashboard[]>>;
  abstract getDashboardLog(dashboard:VOEDashboard, filter:VOELogFilter): Observable<IKediResponse<VOELogData>>;
  abstract getDashboardView(url: string, body: VOEDashboardRequest): Observable<IKediResponse<VOEDashboardView>>;
  // abstract addProcedure(broker:VOEBroker, procedures:VOEProcedure[]): Observable<IKediResponse<VOEBroker>>;
  // abstract deleteProcedure(broker:VOEBroker, procedure: VOEProcedure): Observable<IKediResponse<VOEBroker>>;

  abstract getUsers(): Observable<IKediResponse<VOEUser[]>>;
  abstract getUser(user: VOEUser): Observable<IKediResponse<VOEUser>>;
  abstract saveUser(username: string, user: VOEUser): Observable<IKediResponse<VOEUser>>;
  abstract deleteUser(user: VOEUser): Observable<IKediResponse<VOEUser[]>>;

  abstract getUserGroups(): Observable<IKediResponse<VOEUserGroup[]>>;
  abstract getUserGroup(usergroup: VOEUserGroup): Observable<IKediResponse<VOEUserGroup>>;
  abstract saveUserGroup(usergroupname: string, usergroup: VOEUserGroup): Observable<IKediResponse<VOEUserGroup>>;
  abstract deleteUserGroup(usergroup: VOEUserGroup): Observable<IKediResponse<VOEUserGroup[]>>;
}