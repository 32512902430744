import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { FuseConfigService } from '../../../services/config';
import { FuseConfig, Scheme, Theme, Themes } from '../../../services/config';
import { SessionService, IKediConfig } from '@kedi/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FuseDrawerComponent } from '../../../components/drawer/drawer.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector     : 'settings',
    templateUrl  : './settings.component.html',
    styles       : [
        `
            settings {
                position: static;
                display: block;
                flex: none;
                width: auto;
            }

            @media (screen and min-width: 1280px) {
                empty-layout + settings .settings-cog {
                    right: 0 !important;
                }
            }

        `
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, NgFor, NgClass, RouterModule, MatIconModule, MatTooltipModule, FuseDrawerComponent, MatButtonModule ]
})
export class SettingsComponent implements OnInit, OnDestroy
{
    config: FuseConfig;
    layout: string;
    sessionConfig: IKediConfig;
    scheme: 'dark' | 'light';
    theme: string;
    themes: Themes;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    canUseEmpty: boolean = false;
    canUseClassic: boolean = false;
    canUseCompact: boolean = false;
    canUseClassy: boolean = false;
    canUseDense: boolean = false;
    canUseFuturistic: boolean = false;
    canUseThin: boolean = false;
    canUseCentered: boolean = false;
    canUseEnterprise: boolean = false;
    canUseMaterial: boolean = false;
    canUseModern: boolean = false;

    canUseAuto: boolean = false;
    canUseLight: boolean = false;
    canUseDark: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _fuseConfigService: FuseConfigService,
        public session: SessionService
    )
    {
        this.sessionConfig = session.config;
        this.sessionConfig.site.layoutSettings.AllowedLayouts.forEach(l => {
            switch(l) {
                case "empty": this.canUseEmpty = true; break;
                case "classic": this.canUseClassic = true; break;
                case "classy": this.canUseClassy = true; break;
                case "compact": this.canUseCompact = true; break;
                case "dense": this.canUseDense = true; break;
                case "futuristic": this.canUseFuturistic = true; break;
                case "thin": this.canUseThin = true; break;
                case "centered": this.canUseCentered = true; break;
                case "enterprise": this.canUseEnterprise = true; break;
                case "material": this.canUseMaterial = true; break;
                case "modern": this.canUseModern = true; break;
            }
        });
        this.sessionConfig.site.layoutSettings.AllowedSchemes.forEach(s => {
            switch(s) {
                case "auto": this.canUseAuto = true; break;
                case "light": this.canUseLight = true; break;
                case "dark": this.canUseDark = true; break;
            }
        });

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the themes
        // this._fuseTailwindService.tailwindConfig$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((config) => {
        //         let themes = Object.entries(config.themes);
        //         let tempThemes = [];
        //         themes.forEach(t => {
        //             let temp = this.sessionConfig.site.layoutSettings.AllowedThemes.find(tt => tt == t[0]);
        //             if (temp) tempThemes.push(t);
        //         });
        //         this.themes = tempThemes;
        //     });

        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: FuseConfig) => {

                // Store the config
                this.config = config;

                let themes = config.themes;
                let tempThemes: Themes = [];
                themes.forEach(t => {
                    let temp = this.sessionConfig.site.layoutSettings.AllowedThemes.find(tt => tt == t.id);
                    if (temp) tempThemes.push(t);
                });
                config.themes = tempThemes;                
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the layout on the config
     *
     * @param layout
     */
    setLayout(layout: string): void
    {
        // Clear the 'layout' query param to allow layout changes
        this._router.navigate([], {
            queryParams        : {
                layout: null
            },
            queryParamsHandling: 'merge'
        }).then(() => {

            // Set the config
            this._fuseConfigService.config = {layout};
            this.sessionConfig.site.defaultLayout = layout;
            this.session.saveLayoutSettings();
        });
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void
    {
        this._fuseConfigService.config = {scheme};
        this.sessionConfig.site.defaultScheme = scheme;
        this.session.saveLayoutSettings();
    }

    /**
     * Set the theme on the config
     *
     * @param theme
     */
    setTheme(theme: Theme): void
    {
        this._fuseConfigService.config = {theme};
        this.sessionConfig.site.defaultTheme = theme;
        this.session.saveLayoutSettings();
    }

    setTransparentHeader(val: boolean): void {
        this.sessionConfig.site.layoutSettings.TransparentHeader = val;
        this.session.saveLayoutSettings(true);
    }
}
