import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, concatAll, map, ReplaySubject, throwError, of, forkJoin } from 'rxjs';
import { IKediConfig, IKediResponse, ApiCachedValue, ApiLanguage, ApiLocale, ApiState, KediSharedList, KediSharedValue, KediSharedZone, KediFileActionRequest, ApiRoute } from '../api/api.types';
import { IKediTemplate, IKediTemplateConfigService, IKediTemplateConfirmationConfig, IKediTemplateConfirmService, IKediTemplateDrawerService, SessionCompany, SessionFirm, SessionRole, User } from './session.types';
import { NavigationService } from '../navigation/navigation.service';
import { ApiService } from '../api/api.service';
import { KediFile } from '../../entities/core.entities';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

//import { FuseSplashScreenService } from '../fuse/services/splash-screen/splash-screen.service';
import { Navigation, NavigationItem, IKediShortcut } from '../navigation/navigation.types';

import { TranslocoService } from '@ngneat/transloco';
import * as _ from 'lodash';
// import { KediApp } from '../model/app.class';
// import { KediService } from '../model/service.class';
//import { PrimeNGConfig } from 'primeng/api';
//import { FuseConfigService } from '../fuse/services/config';
//import { MatDialog } from '@angular/material/dialog';
//import { FuseConfirmationConfig, FuseConfirmationService } from '../fuse/services/confirmation';
//import { Router } from '@angular/router';
//import { Shortcut } from '../layout/common/shortcuts/shortcuts.types';
import { MessageService } from 'primeng/api';
//import { FuseDrawerService } from '../fuse/components/drawer/drawer.service';
//import { FuseHorizontalNavigationComponent } from '../fuse/components/navigation/horizontal/horizontal.component';
//import { FuseNavigationService } from '../fuse/components/navigation/navigation.service';
//import { HeaderButtonsComponent } from '../layout/common/header-buttons/header-buttons.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
// import { ChatService } from '../chat/chat.service';

@Injectable({ providedIn: 'root' })
export class SessionService /*extends KediService */ {
  public platform: "Mobile" | "Web" = "Web";
  public platformType: "Android" | "IOS" | "Other" | "Edge" | "Firefox" | "Chrome"

  public shared: KediSharedZone;
  public currentLang: string;
  public currentLangId: number;
  public token: string;
  public AvatarUrl: string;
  private _routes: ApiRoute[] = [];
  public get Navigation(): ReplaySubject<Navigation> {
    return this._navigationService.navigationSubject;
  }

  public checkRoute(route: string) {
    if (route == '/' ||
      route == '/signed-in-redirect' ||
      route == '/confirmation-required' ||
      route == '/forgot-password' ||
      route == '/password-changed' ||
      route == '/reset-password' ||
      route == '/sign-in' ||
      route == '/sign-up' ||
      route == '/sign-out' ||
      route == '/unlock-session'
    ) return true;
    let i = this._routes?.findIndex(r => r.Code == route);
    if (i == null || i < 0) {
      let exists = false;
      this._routes.forEach(r => {
        if (route.startsWith(r.Code)) { exists = true; return; };
      });
      return exists;
    }
    return true;
  }
  public Shortcuts: IKediShortcut[];
  //public Modules: ApiModule[];

  //public Routes: ApiRoute[];
  public User: User;
  public ActiveRole: SessionRole;
  public ActiveFirm: SessionFirm;
  public ActiveCompany: SessionCompany;

  //public NgModules: KediNgModule[] = [];

  public apiUrl: string;
  //public cdnUrl: string;
  public site: string;
  public languages: ApiLanguage[];
  public locales: ApiLocale[];
  get userFullName() {
    return this.User?.FirstName + (this.User?.MiddleName ? "" + this.User?.MiddleName : "") + (this.User?.LastName ? "" + this.User?.LastName : "")
  } 
  //public _activeParentDrawer: any;
  //private _appComponent: any;
  //favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  // private _title: HTMLTitleElement = document.querySelector('#appTitle');
  // private _splashLogo: HTMLImageElement = document.querySelector('#appSplashLogo');
  // private _splashStyle: HTMLLinkElement = document.querySelector('#appSplashStyle');
  toastPosition: string = "top-right"; // center | top-right | top-left | bottom-right | bottom-left | top-center | bottom-center | center
  public lastConfirmationId: string;
  public lastConfirmationCode: string;
  public MustChangePassword: boolean;
  private _changePasswordRoute: string;
  public get ChangePasswordRoute() {
    return this._changePasswordRoute;
  }
  customFunction: any;

  /** User Service Start */
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>({
    Id: null,
    Email: "",
    RoleCode: "",
    UserName: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Avatar: null,
    Status: "",
    EmailVerified: false,
    PasswordVerified: false,
    PhoneVerified: false,
    Phone: "",
    About: "",
    Company: "",
    Country: "tr",
    DefaultLanguage: "tr",
    Title: ""
  });
  private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public template$: BehaviorSubject<IKediTemplate> = new BehaviorSubject<IKediTemplate>(null);
  public get navigator$() {
    return navigator;
  }
  /** User Service End */



  constructor(
    //@Inject(LOCAL_STORAGE) private storage: StorageService,
    @Inject(DOCUMENT) private _document: any,
    private _api: ApiService,
    //private router: Router,
    //private _auth: AuthService,
    //private dialog: MatDialog,
    //private splash: FuseSplashScreenService,
    private _translocoService: TranslocoService,
    //private _fuseSidebarService: FuseSidebarService,
    // private primengConfig: PrimeNGConfig,
    // private _fuseConfigService: FuseConfigService,
    //private _fuseConfirmationService: IKediConfirmationService,
    private _navigationService: NavigationService,
    //private _chatService: ChatService,
    private messageService: MessageService,
    //private _fuseDrawerService: FuseDrawerService,
    //private _fuseNavigationService: FuseNavigationService,
    private titleService: Title,
    private metaService: Meta
  ) {
    /** UserService Start */
    dayjs.locale('tr');
    this.checkPlatform();
    this.template$.next({
      confirmService: null,
      drawerService: null,
      templateService: null
    });
    this.clean();
    this.user$
      .subscribe((user: User) => {
        this.User = user;
      });
    this.template$
      .subscribe((template: IKediTemplate) => {
        this._template = template;
      });
    /** UserService End */

    //super();

    this.shared = new KediSharedZone();
    //this.create<Navigation>("Navigation");
    //this.create<User>("User");
    //this.create<string>("AvatarUrl");
    //this.create<any[]>("Shortcuts");
    //this.create<ApiRoute[]>("Routes");
    //this.create<ApiModule[]>("Modules");
    //this.create<any>("KediEntityType");

    //this.setKediEntity("User",User);


    // this.customFunction = {};
    // this.customFunction["openSettingsBar"] = () => {
    //   _fuseSidebarService.getSidebar("themeOptionsPanel").toggleOpen();
    // };
  }

  /** UserService Start */
  checkPlatform() {
    var ua = navigator.userAgent;
    if (/Android/i.test(ua)) {
      this.platform = "Mobile";
      this.platformType = "Android";
    }
    else if (/iPhone|iPad|iPod|Opera Mini/i.test(ua)) {
      this.platform = "Mobile";
      this.platformType = "IOS";
    }
    else if (/webOS|BlackBerry|IEMobile|Mobile|mobile|CriOS/i.test(ua)) {
      this.platform = "Mobile";
      this.platformType = "Other";
    }
    else if (/Firefox/i.test(ua)) {
      this.platform = "Web";
      this.platformType = "Firefox";
    }
    else if (/Edg/i.test(ua)) {
      this.platform = "Web";
      this.platformType = "Edge";
    }
    else if (/Chrome/i.test(ua)) {
      this.platform = "Web";
      this.platformType = "Chrome";
    }
    else {
      this.platform = "Web";
      this.platformType = "Other";
    }
  }

  public clean() {
    var user: User = {
      Id: null,
      Email: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Avatar: null,
      Status: "",
      EmailVerified: false,
      PasswordVerified: false,
      PhoneVerified: false,
      Phone: "",
      About: "",
      Company: "",
      Country: "tr",
      DefaultLanguage: "tr",
      Title: "",
      RoleCode: "",
      UserName: "",
    }
    this.user = user;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    if (value && value.Id) {
      if (!this._loggedIn.value) this._loggedIn.next(true);
    }
    else {
      if (this._loggedIn.value) this._loggedIn.next(false);
    }
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user;
  }

  get loggedIn$(): Observable<boolean> {
    return this._loggedIn;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    return this.user$;
    // return this._httpClient.get<User>('api/common/user').pipe(
    //     tap((user) => {
    //         this._user.next(user);
    //     })
    // );
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return of(this._user.next(user));
    // return this._httpClient.patch<User>('api/common/user', {user}).pipe(
    //     map((response) => {
    //         this._user.next(response);
    //     })
    // );
  }

  uploadAvatar(file: File): void {
    if (!file) {
      var kedifile: KediFile = {
        Base64Encoded: null
      };
      this.api.setAvatar(kedifile).subscribe(response => {
        if (response.Succeded) {
          this.deleteLocal("img:" + this.User?.Avatar); //this.session.getValue("currentAvatar"));
          var user = _.cloneDeep(this.User);
          user.Avatar = null;
          this._user.next(user);
        }
        else {
          this.showError(response.Message);
        }
      },
        (err: HttpErrorResponse) => this.showError(err.message));
    }
    else {
      this.readBase64(file)
        .then((data) => {
          var kedifile: KediFile = {
            Base64Encoded: data
          };
          this.api.setAvatar(kedifile).subscribe(response => {
            if (response.Succeded) {
              this.deleteLocal("img:" + this.User?.Avatar); //this.session.getValue("currentAvatar"));
              var user = _.cloneDeep(this.User);
              user.Avatar = response?.Result ? this.getImageUrl(response.Result) : null;
              this._user.next(user);
            }
            else {
              this.showError(response.Message);
            }
          },
            (err: HttpErrorResponse) => this.showError(err.message));
        });
    }
  }

  updateProfile(profile: User): Observable<boolean> {
    return this.api.kediPost("account/user", profile, true).pipe(
      map((response: IKediResponse<User>) => {
        if (!response.Succeded) {
          this.showError(response.Message).subscribe();
          throwError(() => new Error(response.Message));
          return false;
        }
        else {
          //Başarılı
          this.User.FirstName = response.Result.FirstName;
          this.User.MiddleName = response.Result.MiddleName;
          this.User.LastName = response.Result.LastName;
          this.User.Phone = response.Result.Phone;
          this.User.Title = response.Result.Title;
          this.User.Company = response.Result.Company;
          this.User.About = response.Result.About;
          this.User.DefaultLanguage = response.Result.DefaultLanguage;
          this.User.Country = response.Result.Country;
          return true;
        }
      })
    );
  }

  getProfile(): Observable<boolean> {
    return this.api.kediGet("account/user", true).pipe(
      map((response: IKediResponse<User>) => {
        if (!response.Succeded) {
          this.showError(response.Message).subscribe();
          throwError(() => new Error(response.Message));
          return false;
        }
        else {
          //Başarılı
          this.User.About = response.Result.About;
          this.User.Avatar = response.Result.Avatar;
          this.User.Company = response.Result.Company;
          this.User.Country = response.Result.Country;
          this.User.DefaultLanguage = response.Result.DefaultLanguage;
          this.User.FirstName = response.Result.FirstName;
          this.User.LastName = response.Result.LastName;
          this.User.MiddleName = response.Result.MiddleName;
          this.User.Phone = response.Result.Phone;
          this.User.Title = response.Result.Title;
          //this.User.Email = response.Result.Email;
          //this.User.EmailVerified = response.Result.EmailVerified;
          //this.User.Id = response.Result.Id;
          //this.User.PasswordVerified = response.Result.PasswordVerified;
          //this.User.PhoneVerified = response.Result.PhoneVerified;
          // this.User.RoleCode = response.Result.RoleCode;
          // this.User.Status = response.Result.Status;
          // this.User.UserName = response.Result.UserName;

          return true;
        }
      })
    );
  }
  /** UserService End */
  private _template: IKediTemplate;

  //private _app: KediApp;
  config: IKediConfig;
  public get api(): ApiService {
    return this._api;
  }

  // public get app(): KediApp {
  //   return this._app;
  // }

  public get IsAnonymous() {
    return !this.User || !this.User.UserName;
  }

  public get IsTemporary() {
    return this.User && (!this.User.EmailVerified || !this.User.PasswordVerified);
  }

  // public get auth(): AuthService {
  //   return this._auth;
  // }

  // public getValue<T>(key: string): T {
  //   return this[key];
  //   //return this.data.getValue<T>(key);
  // }

  // public setValue<T>(key: string, value: T): boolean {
  //   this[key] = value;
  //   return this[key] !== undefined;
  //   //return this.data.setValue<T>(key,value);
  // }

  // public getSubject<T>(key: string): BehaviorSubject<T> {
  //   return this.data.getSubject<T>(key);
  // }

  // get User(): User {
  //   return this.getValue<User>("currentUser");
  //   //return this.user.getValue();
  // }

  // get AvatarUrl(): string {
  //   return this.getValue<string>("currentAvatar");
  // }

  // get Navigation(): FuseNavigation[] {
  //   return this.getValue<FuseNavigation[]>("navigation");
  // }

  // get Shortcuts(): any[] {
  //   return this.getValue<any[]>("shortcuts");
  // }

  public get selectedLanguageObject(): ApiLanguage {
    //return _.find(this.languages, {id: this._translateService.currentLang}) ?? this.languages[0];
    return _.find(this.languages, { id: this._translocoService.getActiveLang() }) ?? this.languages[0];
  }

  public get selectedLanguage(): string {
    return this._translocoService.getActiveLang() ?? this.languages[0].id;
    //return this._translateService.currentLang ?? this.languages[0].id;
  }

  public getImageUrl(key) {
    //return this.cdnUrl ? this.cdnUrl+'api/data/img/'+key : this.apiUrl+'data/img/' + key;
    return !key ? null : this.apiUrl + 'data/img/' + key;
  }
  public setLanguage(lang: string): void {
    this.currentLangId = 0;
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].id == lang) {
        this.currentLangId = i;
        break;
      }
    }
    this.api.setLanguage(lang);
    this.setLocal("lastLanguage", lang);
    this._translocoService.setActiveLang(lang);
    location.reload();
  }

  // public setApp(app: KediApp) {
  //   this._app = app;
  // }

  public getConfig() {
    return this.config;
  }

  public setConfig(e) {
    return this.config = e;
  }

  public clearStorage() {
    localStorage.clear();
  }

  public getLocal(key: string): any {
    return localStorage.getItem(key) || null;
  }

  public setLocal(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  public deleteLocal(key: string): void {
    localStorage.removeItem(key);
  }

  public getToken(): string {
    this.token = this.getLocal("token");
    this.api.setToken(this.token);
    return this.token;
  }

  public setToken(new_token: string): void {
    this.token = new_token;
    this.api.setToken(this.token);
    this.setLocal("token", this.token);
  }

  public deleteToken() {
    this.deleteLocal("token");
  }

  public translate(key: string): string {
    return this._translocoService.translate(key);
    //return this._translateService.instant(key);
  }

  public getCurrentLanguage(): string {
    var lang = this.getLocal("lastLanguage");
    if (!lang || lang == "") lang = "tr";
    this.setLocal("lastLanguage", lang);
    this.currentLang = lang;
    this.currentLangId = 0;
    this.api.setLanguage(this.api.currentLang);
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].id == lang) {
        this.currentLangId = i;
        break;
      }
    }
    return lang;
  }

  public readBase64(file: File): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }


  public readAsBinaryArray(file: File): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        var binary_string: string = window.atob(reader.result as string);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        console.log(bytes.buffer)
        resolve(bytes.buffer);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }

  public fileAction(request: KediFileActionRequest, file: File): Observable<any> {
    var observable: Observable<any> = null;
    if (!file) {
      var kedifile: KediFile = {
        Base64Encoded: null
      };
      observable = this.api.kediPost("file", request, true).pipe(
        map(response => {
          if (response && response.Succeded) {
            return response.Result;
          }
          else {
            if (response) this.showError(response.Message);
            return undefined;
          }
        })
      );
    }
    else {
      observable = from(this.readBase64(file)).pipe(
        map(data => {
          if (!request.File) request.File = { Base64Encoded: data };
          else request.File.Base64Encoded = data;
          return this.api.kediPost("file", request, true).pipe(
            map(response => {
              if (response.Succeded) {
                return response.Result;
              }
              else {
                this.showError(response.Message);
                return undefined;
              }
            })
          );
        }),
        concatAll()
      );
    }
    return observable;
  }

  // Get last value without subscribing to the puppies$ observable (synchronously).
  // getCurrentUser(): User {
  //   return this._currentUserSource.getValue();
  // }

  // private _setCurrentUser(user: User): void {
  //   this._currentUserSource.next(user);
  // }

  // setCurrentUser(user: User): void {
  //   this.currentUser = user;
  //   this._setCurrentUser(user);
  // }

  load() {
    return new Promise<void>((resolve, reject) => {
      this.api.loadConfig()
        .then((config: IKediConfig) => {
          this.config = config;
          this.token = this.getToken();
          var lang = this.getLocal("lastLanguage");
          if (!lang || lang == "") lang = "tr";
          this.currentLang = lang;
          this.api.setLanguage(this.currentLang);
          this.apiUrl = this.config.api.url + '/';
          //this.cdnUrl = this.config.site.cdn ? this.config.site.cdn+'/' : null;
          this.site = this.config.api.site;
          this.api.setToken(this.token);
          this.api.setSite(this.site);
          this.api.getState(this.site)
            .subscribe({
              next: (response: IKediResponse<any>) => {
                if (response.Succeded) {
                  this.setState(response.Result);
                  resolve();
                  // forkJoin([
                  //   (this.config.site.hasChatApp ? this._chatService.start(this) : of(null))
                  //   //this._navigationService.get(),
                  //   // this._messagesService.getAll(),
                  //   // this._notificationsService.getAll(),
                  //   // this._quickChatService.getChats(),
                  //   // this._shortcutsService.getAll(),
                  //   // this._userService.get()
                  // ]).subscribe(result => {
                  //   resolve();
                  // });
                }
                else {
                  alert(response.Message);
                  //this.setState(null);
                  //reject("ERROR_RELOAD_SESSION:" + response.Message);
                  reject("ERROR_RELOAD_SESSION:" + response.Message);
                }
              },
              error: (err: HttpErrorResponse) => {
                this.setState(null);
                this.showMessage("Uygulama sunucusuna bağlanamadı. Lütfen internet bağlantınızı kontrol ediniz. Aksi durumda lütfen sistem yöneticinize bildiriniz.", "Bağlantı Hatası").subscribe((result) => {
                  // alert("Kedi API sunucusuna bağlanamadı. Lütfen internet bağlantınızı kontrol ediniz. Aksi durumda lütfen sistem yöneticinize bildiriniz.");
                  reject("ERROR_RELOAD_SESSION:" + err.message);
                });
                // alert("Kedi API sunucusuna bağlanamadı. Lütfen internet bağlantınızı kontrol ediniz. Aksi durumda lütfen sistem yöneticinize bildiriniz.");
                // reject("ERROR_RELOAD_SESSION:" + err.message);
              }
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  reload() {
    return new Promise<void>((resolve, reject) => {
      this.api.getState(this.site)
        .subscribe((response: IKediResponse<any>) => {
          if (response.Succeded) {
            this.setState(response.Result);
            resolve();
          }
          else {
            alert(response.Message);
            reject("ERROR_RELOAD_SESSION:" + response.Message);
          }
        },
          (err: HttpErrorResponse) => {
            this.setState(null);
            alert("Kedi API sunucusuna bağlanamadı. Lütfen internet bağlantınızı kontrol ediniz. Aksi durumda lütfen sistem yöneticinize bildiriniz.");
            reject("ERROR_RELOAD_SESSION:" + err.message);
          });
    });
  }

  showSplash(duration?: number): void {
    this._document.body.classList.remove('fuse-splash-screen-hidden');
    if (duration && duration > 0) {
      setTimeout(() => {
        this.hideSplash();
      }, duration);
    }
  }

  hideSplash(): void {
    this._document.body.classList.add('fuse-splash-screen-hidden');
  }

  getWebText(type: string) {
    return this.api.kediGet("web/text/" + type, true).pipe(
      map((response: any) => {
        if (!response.Succeded) {
          this.showError(response.Message).subscribe();
          throwError(() => new Error(response.Message));
        }
        else {
          return response.Result;
        }
      })
    );
  }

  private setShortcuts(nodes: NavigationItem[], _shortcuts: any[]) {
    if (!nodes) return;
    nodes.forEach(item => {
      if (item.isShortcut) {
        let _title = this.translate(item.translate);
        _shortcuts.push({
          title: _title,
          letter: _title.substr(0, 1).toUpperCase(),
          type: item.type,
          icon: item.icon,
          url: item.link,
          id: item.id
        });
      }
      this.setShortcuts(item.children, _shortcuts);
    });
  }

  // private checkMenuFunction(c: FuseNavigationItem[]) {
  //   c.forEach(c => {
  //     if (c.function) {
  //       c.function = this.customFunction[c.function];
  //     }
  //     else if (c.children) this.checkMenuFunction(c.children);
  //   });
  // }

  setState(state: ApiState) {
    if (state) {
      this._routes = [...state.Routes];
      this.languages = state.MultiLang.Languages;
      this.config.site.code = state.Settings.Code;
      this.config.site.captchaSiteKey = state.Settings.CaptchaSiteKey;
      this.config.site.siteTitle = state.Settings.SiteTitle;
      this.config.site.siteName = [...state.Settings.SiteName];
      this.config.site.siteDescription = [...state.Settings.SiteDescription];
      this.config.site.siteMotto = [...state.Settings.SiteMotto];
      this.config.site.allowAnonymous = state.Settings.AllowAnonymous;
      this.config.site.canSignUp = state.Settings.CanSignUp;
      this.config.site.canChangeLanguage = state.Settings.CanChangeLanguage;
      this.config.site.canChangeLayout = state.Settings.CanChangeLayout;
      this.config.site.canChangeScheme = state.Settings.CanChangeScheme;
      this.config.site.canChangeTheme = state.Settings.CanChangeTheme;
      this.config.site.loginWith = state.Settings.LoginWith;
      this.config.site.authScreenStyle = state.Settings.AuthScreenStyle;
      this.config.site.defaultLanguage = state.Settings.DefaultLanguage;
      this.config.site.defaultLayout = state.Settings.DefaultLayout;
      this.config.site.defaultRoute = state.Settings.DefaultRoute;
      this.config.site.defaultScheme = state.Settings.DefaultScheme;
      this.config.site.defaultTheme = state.Settings.DefaultTheme;
      this.config.site.multiLanguage = state.Settings.MultiLanguage;
      this.config.site.multiLayout = state.Settings.MultiLayout;
      this.config.site.multiScheme = state.Settings.MultiScheme;
      this.config.site.multiTheme = state.Settings.MultiTheme;
      this.config.site.hasCalendarApp = state.Settings.HasCalendarApp;
      this.config.site.hasChatApp = state.Settings.HasChatApp;
      this.config.site.hasContactsApp = state.Settings.HasContactsApp;
      this.config.site.hasLibraryApp = state.Settings.HasLibraryApp;
      this.config.site.hasMailApp = state.Settings.HasMailApp;
      this.config.site.hasNotesApp = state.Settings.HasNotesApp;
      this.config.site.hasScrumboardApp = state.Settings.HasScurmboardApp;
      this.config.site.hasTasksApp = state.Settings.HasTasksApp;
      this.config.site.resetPasswordMethod = state.Settings.ResetPasswordMethod;
      this.config.site.userSettings = {
        HasUserProfile: state.Settings.UserSettings.HasUserProfile,
        HasUserSettings: state.Settings.UserSettings.HasUserSettings,
        HasUserStatus: state.Settings.UserSettings.HasUserStatus,
        RouteUserProfile: state.Settings.UserSettings.RouteUserProfile,
        RouteUserSettings: state.Settings.UserSettings.RouteUserSettings,
        HasMessages: state.Settings.UserSettings.HasMessages,
        HasShortcuts: state.Settings.UserSettings.HasShortcuts,
        HasNotifications: state.Settings.UserSettings.HasNotifications,
        HasSearch: state.Settings.UserSettings.HasSearch,
        HasFullScreen: state.Settings.UserSettings.HasFullScreen,
        HasUserFirm: state.Settings.UserSettings.HasUserFirm
      },
        this.config.site.signUpSettings = {
          AskCaptcha: state.Settings.SignUpSettings.AskCaptcha,
          AskCompany: state.Settings.SignUpSettings.AskCompany,
          AskContract: state.Settings.SignUpSettings.AskContract,
          AskPrivacy: state.Settings.SignUpSettings.AskPrivacy,
          ContractContent: state.Settings.SignUpSettings.ContractContent,
          PrivacyContent: state.Settings.SignUpSettings.PrivacyContent,
        }
      // TODO: Backend tarafından dinamik alınacak
      this.config.site.layoutSettings = {
        TransparentHeader: state.Settings.TransparentHeader,
        BackgroundImage: state.Settings.BackgroundImage,
        AllowedThemes: state.Settings.AllowedThemes,
        AllowedSchemes: state.Settings.AllowedSchemes,
        AllowedLayouts: state.Settings.AllowedLayouts,
      }
      this.config.site.searchPost = "c002/search";
      this.config.site.metaTitle = state.Settings.MetaTitle;
      this.config.site.metaDescription = state.Settings.MetaDescription;
      this.config.site.metaKeywords = state.Settings.MetaKeywords;
      this.config.site.metaOther = null;
      if (state.Settings.MetaOther && state.Settings.MetaOther.length > 0) {
        this.config.site.metaOther = [];
        state.Settings.MetaOther.forEach(mo => {
          this.config.site.metaOther.push(mo);
        });
      }

      // state.Routes.forEach(route => {
      //   this.router.config.push({
      //     path: route.Name,
      //     component: route.Component
      //   });
      // });
      this.titleService.setTitle(this.config.site.metaTitle);

      if (!this.config.site.metaDescription)
        this.metaService.removeTag("name='description'");
      else
        this.metaService.updateTag({ property: 'description', content: this.config.site.metaDescription })

      if (!this.config.site.metaKeywords)
        this.metaService.removeTag("name='keywords'");
      else
        this.metaService.updateTag({ property: 'keywords', content: this.config.site.metaKeywords })

      if (this.config.site.metaOther && this.config.site.metaOther.length > 0) {
        this.config.site.metaOther.forEach(m => {
          let _split = m?.split("=");
          if (_split && _split.length == 2) {
            this.metaService.updateTag({ property: _split[0], content: _split[1] })
          }
        });
      }

      //this._title.innerHTML = this.config.site.siteName[0];
      //this.favIcon.href = 'https://www.google.com/favicon.ico';

      this._template.templateService.setConfig({
        scheme: this.config.site.defaultScheme,
        theme: this.config.site.defaultTheme,
        layout: this.config.site.defaultLayout
      });
      // this._fuseConfigService.config = {
      //   scheme: this.config.site.defaultScheme,
      //   theme: this.config.site.defaultTheme,
      //   layout: this.config.site.defaultLayout
      // };
      var localeSignature = localStorage.getItem("localeSignature");
      var localeCV: ApiCachedValue = null;
      state.CachedValues.forEach(cv => {
        if (cv.Key == "locale") localeCV = cv;
      });
      if (localeSignature && localeCV && localeSignature == localeCV.Signature) {
        var localeValue = localStorage.getItem("localeValue");
        this.locales = JSON.parse(localeValue);
      }
      else {
        this.locales = state.MultiLang.Locales;
        localStorage.setItem("localeSignature", localeCV.Signature);
        localStorage.setItem("localeValue", JSON.stringify(this.locales));
      }
      var langs: any[] = [];
      this.languages.forEach(l => {
        langs.push({
          id: l.id,
          label: l.title
        });
      });
      this._translocoService.setAvailableLangs(langs);
      var curLang = this.getLocal("lastLanguage");
      if (!curLang) curLang = state.Settings.DefaultLanguage;

      //this._translateService.addLangs(this.languages.map(l => l.id));
      this._translocoService.setDefaultLang(curLang);
      //this._translateService.setDefaultLang(this.languages[0].id);
      this._translocoService.setActiveLang(curLang);

      this.currentLangId = 0;
      for (let i = 0; i < this.languages.length; i++) {
        if (this.languages[i].id == state.Settings.DefaultLanguage) {
          this.currentLangId = i;
          break;
        }
      }
      this.getCurrentLanguage();

      let localeId = 0;
      this.locales.forEach(l => {
        this._translocoService.setTranslation(l.data, l.lang);
        this._translocoService.setTranslationKey("LOGIN.BODY_TITLE1", this.config.site.siteTitle ?? " ", l.lang);
        this._translocoService.setTranslationKey("LOGIN.BODY_TITLE2", this.config.site.siteName[localeId] ?? " ", l.lang);
        this._translocoService.setTranslationKey("LOGIN.BODY_TEXT", this.config.site.siteDescription[localeId] ?? " ", l.lang);
        this._translocoService.setTranslationKey("LOGIN.BODY_MESSAGE", this.config.site.siteMotto[localeId] ?? " ", l.lang);
        localeId++;
      });
      // let primengTranslation = this._translocoService.translateObject('primeng');
      // if (primengTranslation) {
      //   primengTranslation.dayNames = ["Pazar","Pazartesi","Salı","Çarşamba","Perşembe","Cuma","Cumartesi"];
      //   primengTranslation.dayNamesShort = ["Paz","Pts","Sal","Çar","Per","Cum","Cts"];
      //   primengTranslation.dayNamesMin = ["Pz","Ps","Sa","Ça","Pe","Cu","Cs"];
      //   primengTranslation.monthNames= ["Ocak","Şubat","Mart","Nisan","Mayıs","Haziran","Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık"],
      //   primengTranslation.monthNamesShort= ["Oca","Şub","Mar","Nis","May","Haz","Tem","Ağu","Eyl","Eki","Kas","Ara"],
      //   this.primengConfig.setTranslation(primengTranslation);
      // }

      var compact: NavigationItem[] = [];
      var futuristic: NavigationItem[] = [];
      var horizontal: NavigationItem[] = [];
      var buttons: NavigationItem[] = [];
      state.Menu.children.forEach(c => {
        // if (c.type != 'button') {
        compact.push({
          id: c.id,
          code: c.code,
          title: c.title,
          tooltip: c.tooltip,
          position: c.position,
          type: "aside",
          icon: c.icon,
          link: c.link,
          children: c.children
        });
        futuristic.push({
          id: c.id,
          code: c.code,
          title: c.title.toUpperCase(),
          tooltip: c.tooltip,
          position: c.position,
          type: c.type,
          icon: c.icon,
          link: c.link,
          children: c.children
        });
        horizontal.push({
          id: c.id,
          code: c.code,
          title: c.title,
          tooltip: c.tooltip,
          position: c.position,
          type: c.type,
          icon: c.icon,
          link: c.link,
          children: c.children
        });
        // }
        // else {
        //   buttons.push({
        //     id: c.id,
        //     title: c.title,
        //     tooltip: c.tooltip,
        //     type: c.type,
        //     icon: c.icon,
        //     link: c.link,
        //     children: c.children
        //   });
        // }
      });
      var navigation: Navigation = {
        default: state.Menu.children,
        compact: compact,
        futuristic: futuristic,
        horizontal: horizontal
      };
      navigation.compact.forEach(c => {
        c.type = "aside"
      });


      this.Navigation.next(navigation);
      //this.setValue<FuseNavigation[]>("navigation",state.Menu.children);
      let _shortcuts: any[] = [];
      this.setShortcuts(state.Menu.children, _shortcuts);
      this.Shortcuts = _shortcuts;
      this.MustChangePassword = state.Session.MustChangePassword;
      this._changePasswordRoute = state.Settings.ChangePasswordRoute;
      if (!this._changePasswordRoute) this._changePasswordRoute = "core/usersettings/security";
      // if (this.config.site.hasChatApp) {
      //   this._chatService.apiUrl = this.apiUrl;
      // }
      this.ActiveRole = state.Session.ActiveRole;
      this.ActiveFirm = state.Session.ActiveFirm;
      this.ActiveCompany = state.Session.ActiveCompany;
      if (state.User) {
        this.user = state.User;
        if (this.user?.Avatar) this.user.Avatar = this.getImageUrl(this.user.Avatar);
      }
      else this.clean();
      //this.setValue<any[]>("shortcuts", _shortcuts);
      //this.Routes = state.Routes;
      //this.setValue<ApiRoute[]>("routes",state.Routes);
      //this.Modules = state.Modules;
      // this.NgModules.forEach(ngm => {
      //   let _module = this.Modules.find(m => m.KediModuleCode == ngm.ModuleCode);
      //   if (_module) {
      //     ngm.ApiModule = _module;
      //     this.Routes.filter(r => r.ModuleKey == _module.Key).forEach(r => {
      //       ngm.ApiRoutes.push(r);
      //     });
      //   }
      // });
    }
    else {
      this.clean();
      this.User = null;
      this.AvatarUrl = null;
      this.Navigation.next(null);
      this.Shortcuts = null;
      //this.Routes = null;
      //this.Modules = null;
    }
  }

  setTemplateDrawerService(drawerService: IKediTemplateDrawerService) {
    this._template.drawerService = drawerService;
  }

  setTemplateConfirmService(confirmService: IKediTemplateConfirmService) {
    this._template.confirmService = confirmService;
  }

  setTemplateConfigService(templateService: IKediTemplateConfigService) {
    this._template.templateService = templateService;
  }

  showConfirm(config?: IKediTemplateConfirmationConfig): Observable<any> {
    return this._template.confirmService ? this._template.confirmService.showConfirm(config) : null;
  }

  showError(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "HATA", message: text, dismissible: false, icon: { color: "error", name: "error", show: true }, actions: { cancel: { label: "Kapat", show: true }, confirm: { show: false } } });
  }

  showWarning(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "UYARI", message: text, dismissible: false, icon: { color: "warning", name: "warning", show: true }, actions: { cancel: { label: "Tamam", show: true }, confirm: { show: false } } });
  }

  askWarning(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "UYARI", message: text, dismissible: false, icon: { color: "warning", name: "warning", show: true }, actions: { confirm: { label: "Tamam", show: true, color: "primary" }, cancel: { show: true, label: "İptal" } } });
  }

  askQuestion(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "BİLGİ", message: text, dismissible: false, icon: { color: "info", name: "info", show: true }, actions: { confirm: { label: "Tamam", show: true, color: "primary" }, cancel: { show: true, label: "İptal" } } });
  }

  showMessage(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "BİLGİ", message: text, dismissible: false, icon: { color: "info", name: "info", show: true }, actions: { cancel: { label: "Tamam", show: true }, confirm: { show: false } } });
  }

  toastError(text: string, title?: string, key?: string) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'error', summary: title ?? "HATA", detail: text });
  }

  toastWarning(text: string, title?: string, key?: string) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'warn', summary: title ?? "UYARI", detail: text });
  }

  toastInfo(text: string, title?: string, key?: string) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'info', summary: title ?? "BİLGİ", detail: text });
  }

  toastSuccess(text: string, title?: string, key?: string) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'success', summary: title ?? "BAŞARILI", detail: text });
  }

  toastConfirm(key: string, text: string, title?: string, data?: any) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'warn', summary: title ?? "UYARI", detail: text, sticky: true, closable: false, data: data });
  }

  toastClose(key: string, value?: any) {
    this.messageService.clear(key);
  }

  toastCloseAll() {
    this.messageService.clear();
  }

  // // // showMessage(text: string, title: string = "") {
  // // //   return this.openDialog({
  // // //     data: text,
  // // //     title: title,
  // // //     height: 200
  // // //   });
  // // // }

  // // // showWarning(text: string, title: string = "") {
  // // //   return this.openDialog({ data: text, title: title, type: KediDialogType.WARNING });
  // // // }

  // // // showError(text: string, title: string = "") {
  // // //   return this.openDialog({ data: text, title: title, type: KediDialogType.ERROR });
  // // // }

  // askQuestion(text: string, title: string = "") {
  //   return this.openDialog({ data: text, title: title, type: KediDialogType.QUESTION });
  // }

  // confirm(text: string, title: string = "") {
  //   return this.openDialog({ data: text, title: title, type: KediDialogType.CONFIRM });
  // }

  // showDialog(options: KediDialogOptions) {
  //   options.type = KediDialogType.CUSTOM;
  //   return this.openDialog(options);
  // }

  toggleShortcut(key: string) {
    return new Promise<boolean>((resolve) => {
      this.api.kediPost("web/shortcut/" + key, null, true)
        .subscribe({
          next: (response: IKediResponse<any>) => {
            if (response.Succeded) {
              resolve(true);
            }
            else {
              this.showError(response.Message);
              resolve(false);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err.message);
            resolve(false);
          }
        });
    });
  }

  public clearCache(scope: string = "ALL") {
    var lastLanguage = this.getLocal("lastLanguage");
    var lastEmail = this.getLocal("lastEmail");
    var token = this.getLocal("token");
    var rememberMe = this.getLocal("rememberMe");
    this.clearStorage();
    if (lastLanguage) this.setLocal("lastLanguage", lastLanguage);
    if (lastEmail) this.setLocal("lastEmail", lastEmail);
    if (token) this.setLocal("token", token);
    if (rememberMe) this.setLocal("rememberMe", rememberMe);
  }

  public Guid(): string {
    const ho = (n, p) => n.toString(16).padStart(p, 0); /// Return the hexadecimal text representation of number `n`, padded with zeroes to be of length `p`
    const view = new DataView(new ArrayBuffer(16)); /// Create a view backed by a 16-byte buffer
    crypto.getRandomValues(new Uint8Array(view.buffer)); /// Fill the buffer with random data
    view.setUint8(6, (view.getUint8(6) & 0xf) | 0x40); /// Patch the 6th byte to reflect a version 4 UUID
    view.setUint8(8, (view.getUint8(8) & 0x3f) | 0x80); /// Patch the 8th byte to reflect a variant 1 UUID (version 4 UUIDs are)
    return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(view.getUint16(6), 4)}-${ho(view.getUint16(8), 4)}-${ho(view.getUint32(10), 8)}${ho(view.getUint16(14), 4)}`; /// Compile the canonical textual form from the array data
  }

  public registerSharedList<T>(_key: string, _source?: any): KediSharedList<T> {
    return this.shared.registerList<T>(_key, _source);
  }

  public unregisterSharedList<T>(_key: string): void {
    this.shared.unregisterList<T>(_key);
  }

  public registerSharedValue<T>(_key: string, _source?: any): KediSharedValue<T> {
    return this.shared.registerValue<T>(_key, _source);
  }

  public unregisterSharedValue<T>(_key: string): void {
    this.shared.unregisterValue<T>(_key);
  }

  public getSharedListValue<T>(_key: string): T[] {
    return this.shared.getListValue<T>(_key);
  }

  public getSharedRowValue<T>(_key: string): T {
    return this.shared.getRowValue<T>(_key);
  }

  public getSharedValue<T>(_key: string): T {
    return this.shared.getValue<T>(_key);
  }

  public setSharedListValue<T>(_key: string, _value: T[]) {
    return this.shared.setListValue<T>(_key, _value);
  }

  public setSharedRowValue<T>(_key: string, _value: T) {
    return this.shared.setRowValue<T>(_key, _value);
  }

  public setSharedValue<T>(_key: string, _value: T) {
    return this.shared.setValue<T>(_key), _value;
  }

  public sharedList<T>(_key: string): BehaviorSubject<T[]> {
    return this.shared.list<T>(_key);
  }

  public sharedRow<T>(_key: string): BehaviorSubject<T> {
    return this.shared.row<T>(_key);
  }

  public sharedValue<T>(_key: string): BehaviorSubject<T> {
    return this.shared.value<T>(_key);
  }

  public sharedEnum(_key: string): BehaviorSubject<any> {
    let _enum = this.shared.value<any>(_key);
    if (!_enum) {
      let _val: any = {};
      // _enum = this.shared.registerValue<any>(_key, null).data;
      // var obs = this.api.kediPost<any[]>("data/enum/"+_key,null,true).pipe(
      //   map(response => {

      //     if (response.Succeded) {
      //       response.Result.forEach(enumValue => {
      //         _val[enumValue.Key] = {
      //           id: enumValue.Id,
      //           text: enumValue.Text,
      //           value: enumValue.Id
      //         }
      //       });
      //       this.shared.registerValue<any>(_key, _val);
      //     }
      //     else {
      //       this.showError(response.Message);
      //     }
      //   })
      // );
      // obs.subscribe(_enum);
      // return _enum;
      this.api.kediPost<any[]>("data/enum/" + _key, null, true).subscribe(response => {
        if (response.Succeded) {
          response.Result.forEach(enumValue => {
            _val[enumValue.Key] = {
              id: enumValue.Id,
              text: enumValue.Text,
              value: enumValue.Id
            }
          });
          this.shared.registerValue<any>(_key, _val);
        }
        else {
          this.showError(response.Message);
        }
      });
      return this.shared.registerValue<any>(_key, null).data;
    }
    else return _enum;
  }

  public getSharedEnumText(_key: string, _value: any) {
    let _enum = this.shared.value<any>(_key).getValue();
    if (!_enum || !_enum["_" + _value]) return null;
    return _enum["_" + _value].text[this.currentLangId];
  }

  public getSharedEnumTextList(_key: string, success: (values: (string | null)[]) => void) {
    let _enum = this.shared.value<any>(_key).getValue();
    if (!_enum) return null;
    let arr: string[] = [];
    Object.keys(_enum).forEach((prop: string) => arr.push(_enum[prop].value));
    success(arr);
  }

  public getSharedEnumValue(_key: string, _text: any) {
    let _enum = this.shared.value<any>(_key).getValue();
    if (!_enum) return null;
    let _val = null;
    Object.keys(_enum).forEach(_key => {
      if (_enum[_key].text[this.currentLangId] == _text) {
        _val = _enum[_key].id;
        return;
      }
    });
    return _val;
  }
  /*
    public setKediEntity(className: string, type: any) {
      if (this.KediEntityType == null)
        this.KediEntityType = {};
      if (this.KediEntityType[className] === undefined || this.KediEntityType[className] === null) {
        this.KediEntityType[className] = type;
      }
    }
    */

  toggleDrawer(drawerId: string) {
    if (this._template.drawerService) this._template.drawerService.toggleDrawer(drawerId);
    // const drawer = this._fuseDrawerService.getComponent(drawerId);
    // drawer?.toggle();
  }

  closeDrawer(drawerId: string) {
    if (this._template.drawerService) this._template.drawerService.closeDrawer(drawerId);
    // const drawer = this._fuseDrawerService.getComponent(drawerId);
    // drawer?.close();
  }

  openDrawer(drawerId: string) {
    if (this._template.drawerService) this._template.drawerService.openDrawer(drawerId);
    // const drawer = this._fuseDrawerService.getComponent(drawerId);
    // drawer?.open();
  }

  getParentDrawer() {
    if (this._template.drawerService) return this._template.drawerService.getParentDrawer();
    else return null;
    // return this._activeParentDrawer;
  }

  setParentDrawer(drawer: any) {
    if (this._template.drawerService) return this._template.drawerService.setParentDrawer(drawer);
    else return null;
    // this._activeParentDrawer = drawer;
  }

  saveLayoutSettings(reload: boolean = false) {
    let setting: any = {
      Code: this.config.site.code,
      TransparentHeader: this.config.site.layoutSettings.TransparentHeader,
      DefaultTheme: this.config.site.defaultTheme,
      DefaultScheme: this.config.site.defaultScheme,
      Defaultlayout: this.config.site.defaultLayout,
    };
    this.api.kediPost("web/settings/layout/save", setting, true).subscribe((response) => {
      if (reload) location.reload();
    });
  }

  dateStr(date: string | Date, type?: string) {
    return dayjs(date).format(!type || type == "md" ? "D MMM YYYY ddd" : (type == "lg" ? "D MMMM YYYY dddd" : (type != "" ? type : "DD/MM/YYYY")));
  }
  dateTimeStr(date: string | Date, type?: string) {
    return dayjs(date).format(!type || type == "md" ? "D MMM YYYY ddd HH:mm" : (type == "lg" ? "D MMMM YYYY dddd HH:mm" : (type != "" ? type : "DD/MM/YYYY HH:mm")));
  }
  timeStr(date: string | Date) {
    return dayjs(date).format("HH:mm");
  }
  toOADateStr(date: string | Date) {
    return (Math.abs(dayjs(date).toDate().getTime() - new Date(Date.UTC(1899, 11, 30)).getTime())) / (24 * 60 * 60 * 1000)
  }
  toISOString(date: string | Date) {
    return dayjs(date).format().substring(0,19) + "Z";
  }

  formatDataByView(cols: any[], data: any[]) {
    cols.forEach(c => {
      if (c.datatype?.startsWith('Date')) {
        data.forEach(d => {
          d[c.field] = dayjs(d[c.field]).toDate()
        });
      }
      else if (c.type == "subgrid") {
        data.forEach(d => {
          this.formatDataByView(c.detailGridColumnDefs, d[c.field]);
        });
      }
    });
  }

  toNumberStr = function (value: string | number, decimals: number) {
    let _number = typeof value === "string" ? parseFloat(value) : value;
    if ((1.1).toLocaleString().indexOf(".") >= 0) {
      return _number.toFixed(decimals).replace(/^[+-]?\d+/, function (int) {
        return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      });
    }
    else {
      return _number.toFixed(decimals).replace(".", ",").replace(/^[+-]?\d+/, function (int) {
        return int.replace(/(\d)(?=(\d{3})+$)/g, '$1.');
      });
    }
  };

  beforeCallback(_callback: (() => boolean | Observable<boolean>) | (() => boolean | Observable<boolean>)[]): Observable<boolean> {
    let observables: Observable<boolean>[] = [];
    if (_callback) {
      if (Array.isArray(_callback)) {
        _callback.forEach(_c => {
          let result = _c();
          observables.push(typeof result === 'boolean' ? of(result) : result);
        });
      }
      else {
        let result = _callback();
        observables.push(typeof result === 'boolean' ? of(result) : result);
      }
    }
    if (observables.length == 0) observables.push(of(true));
    return forkJoin(observables).pipe(
      map((list) => {
        return (list.findIndex(e => !e) < 0)
      })
    )
  }

   isUUID (uuid:string) {
    let result = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return result && result.length > 0 && result[0];
  }


  // refreshBadges(badgeValues: KediBadgeValue[] ) {
  //   const mainNavigationComponent = this._fuseNavigationService.getComponent<FuseHorizontalNavigationComponent>('mainNavigation');
  //   const headerButtonsComponent = this._fuseNavigationService.getComponent<HeaderButtonsComponent>('headerButtons');
  //   if (mainNavigationComponent || headerButtonsComponent) {
  //       const mainNavigation = mainNavigationComponent.navigation ?? headerButtonsComponent.navigation;
  //       if (mainNavigation) {
  //         badgeValues.forEach(bv => {
  //           const menuItem = this._fuseNavigationService.getItemByCode(bv.code, mainNavigation);
  //           if (menuItem) {
  //             if (!menuItem.badge) {
  //                 menuItem.badge = {
  //                     title: bv.value.toString(),
  //                     classes: bv.classes // "px-2 bg-teal-400 text-black rounded-lg"
  //                 };
  //             }
  //             else {
  //                 menuItem.badge.title = bv.value.toString();
  //                 if (bv.classes) menuItem.badge.classes = bv.classes; //"px-2 bg-teal-400 text-black rounded-lg";
  //             }
  //           }
  //         });
  //       }
  //       mainNavigationComponent?.refresh();
  //       headerButtonsComponent?.refresh();
  //   }
  // }
}
