import { Injectable } from '@angular/core';
import { SessionService } from '@kedi/core';
import { IKediTemplateDrawerService } from '@kedi/core';
import { FuseDrawerComponent } from './drawer.component';

@Injectable({
    providedIn: 'root'
})
export class FuseDrawerService implements IKediTemplateDrawerService
{
    private _componentRegistry: Map<string, FuseDrawerComponent> = new Map<string, FuseDrawerComponent>();
    public _activeParentDrawer: any;

    /**
     * Constructor
     */
    constructor(private _session: SessionService)
    {
        this._session.setTemplateDrawerService(this);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register drawer component
     *
     * @param name
     * @param component
     */
    registerComponent(name: string, component: FuseDrawerComponent): void
    {
        this._componentRegistry.set(name, component);
    }

    /**
     * Deregister drawer component
     *
     * @param name
     */
    deregisterComponent(name: string): void
    {
        this._componentRegistry.delete(name);
    }

    /**
     * Get drawer component from the registry
     *
     * @param name
     */
    getComponent(name: string): FuseDrawerComponent | undefined
    {
        return this._componentRegistry.get(name);
    }

    toggleDrawer(drawerId: string) {
        const drawer = this.getComponent(drawerId);        
        drawer?.toggle();
      }
    
      closeDrawer(drawerId: string) {
          const drawer = this.getComponent(drawerId);        
          drawer?.close();
      }
    
      openDrawer(drawerId: string) {
          const drawer = this.getComponent(drawerId);        
          drawer?.open();
      }  
    
      getParentDrawer() {
        return this._activeParentDrawer;
      }
    
      setParentDrawer(drawer: any) {
        this._activeParentDrawer = drawer;
      }    
}
