import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { Subject, takeUntil, debounceTime, filter, map } from 'rxjs';
import { fuseAnimations } from '../../../animations/public-api';
import { SessionService } from '@kedi/core';
import { Router } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Overlay } from '@angular/cdk/overlay';

@Component({
    selector     : 'user-firm',
    templateUrl  : './user-firm.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'fuseSearch',
    animations   : fuseAnimations,
    standalone: true,
    imports: [ NgIf, NgFor, ReactiveFormsModule, MatAutocompleteModule, MatButtonModule, MatTooltipModule, MatFormFieldModule, MatIconModule, MatInputModule, MatAutocompleteModule, TranslocoModule, OverlayPanelModule],
    providers    : [
        {
            provide   : MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: () =>
            {
                const overlay = inject(Overlay);
                return () => overlay.scrollStrategies.block();
            },
        },
    ]
})
export class UserFirmComponent implements OnChanges, OnInit, OnDestroy
{
    // @Input() appearance: 'basic' | 'bar' = 'basic';
    // @Input() debounce: number = 300;
    // @Input() minLength: number = 2;
    @Output() firmChanged: EventEmitter<any> = new EventEmitter<any>();
    selectedValue: any;
    opened: boolean = false;
    resultSets: any[];
    private _matAutocomplete: MatAutocomplete;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    get ActiveFirm() {
        return this.session.ActiveFirm;
    }
    /**
     * Constructor
     */
    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2,
        private _router: Router,
        public session: SessionService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any
    {
        return {
            // 'search-appearance-bar'  : this.appearance === 'bar',
            // 'search-appearance-basic': this.appearance === 'basic',
            // 'search-opened'          : this.opened
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef)
    {
        // If the value exists, it means that the search input
        // is now in the DOM, and we can focus on the input..
        if ( value )
        {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {

                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    /**
     * Setter for mat-autocomplete element reference
     *
     * @param value
     */
     @ViewChild('matAutocomplete')
     set matAutocomplete(value: MatAutocomplete)
     {
         this._matAutocomplete = value;
     }
 
 
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Appearance
        if ( 'appearance' in changes )
        {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        /*
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {

                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if ( !value || value.length < this.minLength )
                    {
                        this.resultSets = null;
                    }

                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter(value => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                let url = this.session.config.site.searchPost;
                this.session.api.kediPost<any>(url, {query: value},false)
                    .subscribe((response) => {
                        if (!response || !response.Succeded) {
                            this.resultSets = [];
                            this.session.showError(response.Message);
                        }
                        else {
                            this.resultSets = response.Result;
                        }
                        this.search.next(this.resultSets);
                    });
            });
            */
    }

    getLink(link:string): string {
        return link.split("?")[0];
    }

    getParams(link:string): any {
        let obj = {};
        let tempSplit = link.split("?");
        if (tempSplit.length > 1) {
            tempSplit[1].split("&").forEach(p => {
                let param = p.split("=");
                if (param.length > 1) {
                    obj[param[0]] = param[1];
                }
            })
        }
        return obj;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void
    {
        // Escape
        if ( event.code === 'Escape' )
        {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
            // if ( this.appearance === 'bar' && !this._matAutocomplete.isOpen )
            // {
            //     this.close();
            // }
        }       
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void
    {
        // Return if it's already opened
        if ( this.opened )
        {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void
    {
        // Return if it's already closed
        if ( !this.opened )
        {
            return;
        }

        // Clear the search input
        //this.searchControl.setValue('');

        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    getImageUrl(key: string) {
        return key ? this.session.getImageUrl(key) : null;
    }

    selectedOption(event) {
        this.selectedValue = event.option.value;
        if (!this.selectedValue) return;
        this._router.navigate([this.getLink(this.selectedValue.link)], { queryParams: this.getParams(this.selectedValue.link) });
        this.close();
    }
}
