import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, catchError, switchMap, map } from 'rxjs';
import { AuthUtils } from '../auth/auth.utils';
import { User } from '../session/session.types';
import { SessionService } from '../session/session.service';
import { IKediResponse } from '../api/api.types';

@Injectable({providedIn: 'root'})
export class AuthService
{
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private session = inject(SessionService);
    private _confirmed: boolean = false;

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        this.session.setToken(token);
        //localStorage.setItem('token', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('token');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(identity: string, useEmail:boolean): Observable<IKediResponse<any>>
    {
        var returnUrl = location.href.replace("forgot-password","") + "reset-password";
        return this.session.api.forgotPassword(identity, returnUrl, useEmail).pipe(
            catchError((error) => {
                return of({ Succeded: false, Message: error, Result: null });
            }),
            switchMap((response) => {
                if (response.Succeded) {
                    return of(response);
                }
                else {
                    throwError(() => new Error(response.Message));
                }
                return of(response);
            })
        );
    }

    /**
     * Confirm email
     *
     */
     confirmEmail(userid: string, confirmtoken: string): Observable<any>
     {
         return this.session.api.confirmEmail(userid, confirmtoken).pipe(
             catchError((error) => {
                 return of({ Succeded: false, Message: error, Result: null });
             })
         );
     }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(userid: string, resettoken: string, newPassword: string): Observable<any>
    {
        return this.session.api.resetPassword(userid, resettoken, newPassword).pipe(
            catchError((error) => {
                return of({ Succeded: false, Message: error, Result: null });
            })
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(username: string, password: string, language: string, returnUrl: string): Observable<any>
    {
        // Throw error, if the user is already logged in
        // if ( this._authenticated )
        // {
        //     return throwError('User is already logged in.');
        // }
        return this.session.api.authenticate(username, password, this.session.site, language, returnUrl).pipe(
            catchError((error) => {
                return of({ Succeded: false, Message: error, Result: null });
            }),
            switchMap((response) => {
                if (response.Succeded) {
                    this.session.api.setToken(response.Result.Token);
                    localStorage.setItem("token",response.Result.Token);
                    this._authenticated = true;
                    this._confirmed = true;
                    var user: User = {
                        Email: response.Result.Email,
                        Id: response.Result.Id,
                        UserName: response.Result.UserName,
                        FirstName: (response.Result.FirstName??""),
                        MiddleName: (response.Result.MiddleName??""),
                        LastName: (response.Result.LastName??""),
                        // Name: ((response.Result.FirstName??"") + " " + (response.Result.MiddleName??"") + " " + (response.Result.LastName??"")).replace("  "," "),
                        Avatar: response.Result.Avatar ? this.session.getImageUrl(response.Result.Avatar) : null, //"assets/images/avatars/none.jpg",
                        Status: response.Result.Status,
                        PhoneVerified: response.Result.PhoneVerified,
                        EmailVerified: response.Result.EmailVerified,
                        PasswordVerified: response.Result.PasswordVerified,
                        Phone: response.Result.Phone,
                        About: response.Result.About,
                        Company: response.Result.Company,
                        Country: response.Result.Country,
                        DefaultLanguage: response.Result.DefaultLanguage,
                        Title: response.Result.Title,
                        RoleCode: response.Result.RoleCode
                    };
                    this.session.user = user;
                    return of(response);
                }
                else {
                    this.session.deleteToken();
                    return of({ Succeded: false, Message: response.Message, Result: null });
                }
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        if (!this.accessToken) return of(false);
        return this.session.api.loginByToken()
        .pipe(
            catchError((error) => {
                this.signOut();
                this.session.reload();
                return of(false);
            }),
            switchMap((response: any) => {
                if (response.Result) {
                    this.accessToken = response.Result.Token;

                    // Set the authenticated flag to true
                    this._authenticated = true;
                    this._confirmed = true;
    
                    // Store the user on the user service
                    var user: User = {
                        Email: response.Result.Email,
                        Id: response.Result.Id,
                        UserName: response.Result.UserName,
                        FirstName: (response.Result.FirstName??""),
                        MiddleName: (response.Result.MiddleName??""),
                        LastName: (response.Result.LastName??""),
                        // Name: ((response.Result.FirstName??"") + " " + (response.Result.MiddleName??"") + " " + (response.Result.LastName??"")).replace("  "," "),
                        Avatar: response.Result.Avatar ? this.session.getImageUrl(response.Result.Avatar) : null, //"assets/images/avatars/none.jpg",
                        Status: response.Result.Status,
                        PhoneVerified: response.Result.PhoneVerified,
                        EmailVerified: response.Result.EmailVerified,
                        PasswordVerified: response.Result.PasswordVerified,
                        Phone: response.Result.Phone,
                        About: response.Result.About,
                        Company: response.Result.Company,
                        Country: response.Result.Country,
                        DefaultLanguage: response.Result.DefaultLanguage,
                        Title: response.Result.Title,
                        RoleCode: response.Result.RoleCode
                    };
                    this.session.user = user;
                    return of(true);
                }
                else {
                    this.signOut();
                    this.session.reload();
                    return of(false);
                }
            })
        );
    }

    signInViaEmail(email: string, language: string, returnUrl: string): Observable<any>
    {
        return this.session.api.authenticateByEmail(email, this.session.site, language, returnUrl).pipe(
            catchError((error) => {
                return of({ Succeded: false, Message: error, Result: null });
            }),
            switchMap((response) => {
                if (response.Succeded) {
                    this.session.api.setToken(response.Result.Token);
                    localStorage.setItem("token",response.Result.Token);
                    this._authenticated = true;
                    this._confirmed = false;
                    var user: User = {
                        Email: response.Result.Email,
                        Id: response.Result.Id,
                        UserName: response.Result.UserName,
                        FirstName: (response.Result.FirstName??""),
                        MiddleName: (response.Result.MiddleName??""),
                        LastName: (response.Result.LastName??""),
                        // Name: ((response.Result.FirstName??"") + " " + (response.Result.MiddleName??"") + " " + (response.Result.LastName??"")).replace("  "," "),
                        Avatar: response.Result.Avatar ? this.session.getImageUrl(response.Result.Avatar) : null, //"assets/images/avatars/none.jpg",
                        Status: response.Result.Status,
                        PhoneVerified: response.Result.PhoneVerified,
                        EmailVerified: response.Result.EmailVerified,
                        PasswordVerified: response.Result.PasswordVerified,
                        Phone: response.Result.Phone,
                        About: response.Result.About,
                        Company: response.Result.Company,
                        Country: response.Result.Country,
                        DefaultLanguage: response.Result.DefaultLanguage,
                        Title: response.Result.Title,
                        RoleCode: response.Result.RoleCode
                    };
                    this.session.user = user;
                    return of(response);
                }
                else {
                    this.session.deleteToken();
                    return of({ Succeded: false, Message: response.Message, Result: null });
                }
            })
        );
    }    
    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Set the authenticated flag to false
        this._authenticated = false;
        // Remove the access token from the local storage
        this.accessToken = null;
        localStorage.removeItem('token');
        this.session.clean();

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }, adConfirmation: boolean, signupToken: string, ): Observable<any>
    {
        return this.session.api.register(user.name, user.email, user.email, user.password, adConfirmation, location.origin + "/sign-in", signupToken);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
