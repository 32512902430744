import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { merge } from 'lodash-es';
import { FuseConfirmationDialogComponent } from './dialog/dialog.component';
import { FuseConfirmationConfig } from './confirmation.types';
import { SessionService, IKediTemplateConfirmService } from '@kedi/core';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class FuseConfirmationService implements IKediTemplateConfirmService
{
    private _matDialog: MatDialog = inject(MatDialog);
    private _session: SessionService = inject(SessionService);

    private _defaultConfig: FuseConfirmationConfig = {
        title      : 'Confirm action',
        message    : 'Are you sure you want to confirm this action?',
        icon       : {
            show : true,
            name : 'heroicons_outline:exclamation',
            color: 'warn'
        },
        actions    : {
            confirm: {
                show : true,
                label: 'Confirm',
                color: 'warn'
            },
            cancel : {
                show : true,
                label: 'Cancel'
            }
        },
        dismissible: false
    };

    /**
     * Constructor
     */
    constructor(
    )
    {
        this._session.setTemplateConfirmService(this);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent>
    {
        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus   : false,
            disableClose: !userConfig.dismissible,
            data        : userConfig,
            panelClass  : 'fuse-confirmation-dialog-panel'
        });
    }

    showConfirm(config?:FuseConfirmationConfig): Observable<any> {
        const dialogRef = this.open(config);
        return dialogRef.afterClosed();
      }
}
