import { NgModule } from '@angular/core';
import { TimedTaskService } from './timed-task.service';

@NgModule({
    declarations: [],
    imports     : [],
    providers   : [
        TimedTaskService
    ]
})
export class TimedTaskServiceModule
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
