import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '../confirmation.types';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector     : 'fuse-confirmation-dialog',
    templateUrl  : './dialog.component.html',
    styleUrls    : ['./dialog.component.scss'],

    // styles       : [
    //     /* language=SCSS */
    //     `
    //         .fuse-confirmation-dialog-panel {
    //             @screen md {
    //                 @apply w-128;
    //             }

    //             .mat-dialog-container {
    //                 padding: 0 !important;
    //             }
    //         }
    //     `
    // ],
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    imports      : [ NgIf, MatButtonModule, MatDialogModule, MatIconModule, NgClass ],
})
export class FuseConfirmationDialogComponent
{
    /**
     * Constructor
     */
     constructor(@Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig)
    {
    }
}
