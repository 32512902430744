import { NgModule } from '@angular/core';
import { IntegrationService } from './integration.service';

@NgModule({
    declarations: [],
    imports     : [],
    providers: [
        IntegrationService
    ]
})
export class IntegrationServiceModule
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
