import { filter, of, switchMap, take } from 'rxjs';
import { AuthService } from '../auth.service';
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, NavigationError, Router } from '@angular/router';
import { SessionService } from '../../session/session.service';

export const DefaultGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const _router: Router = inject(Router);
    const _session: SessionService = inject(SessionService);
    _router.events.pipe(
        filter(event => event instanceof NavigationError),
        take(1)
    )
    .subscribe((a) => {
        console.log("Navigation Error");
        console.log(a);
        _router.navigate([_session.config.site.defaultRoute]); //['default']);
    });      

    if (_session.MustChangePassword == true && state.url.replace(/^\/+|\/+$/g, '') != _session.ChangePasswordRoute.replace(/^\/+|\/+$/g, '')) return _router.navigate([_session.ChangePasswordRoute]);
    if (_session.config.site.defaultRoute) return _router.navigate([_session.config.site.defaultRoute]);
    else return of(true);

    //const joinedURL = `/${segments.join('/')}`;
    // const redirectURL = joinedURL === '/sign-out' ? '/' : joinedURL;
    // if (_session.MustChangePassword == true && joinedURL.replace(/^\/+|\/+$/g, '') != _session.ChangePasswordRoute.replace(/^\/+|\/+$/g, '')) return _router.navigate([_session.ChangePasswordRoute]);
    // if (_session.config.site.defaultRoute) return _router.navigate([_session.config.site.defaultRoute]);
    // else return of(true);
};

/*
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanMatch, NavigationError, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { filter, Observable, take } from 'rxjs';
import { SessionService } from '../../session/session.service';

@Injectable({
    providedIn: 'root'
})
export class DefaultGuard implements CanMatch, CanActivate
{
    constructor(
        private _router: Router,
        private _session: SessionService

    )
    {
        this._router.events
        .pipe(
            filter(event => event instanceof NavigationError),
            take(1)
        )
        .subscribe((a) => {
            console.log("Navigation Error");
            console.log(a);
            this._router.navigate([this._session.config.site.defaultRoute]); //['default']);
        });        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        if (this._session.MustChangePassword == true && state.url.replace(/^\/+|\/+$/g, '') != this._session.ChangePasswordRoute.replace(/^\/+|\/+$/g, '')) return this._router.navigate([this._session.ChangePasswordRoute]);
        if (this._session.config.site.defaultRoute) return this._router.navigate([this._session.config.site.defaultRoute]);
        else return true;
    }


    canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._check(segments);
    }

    private _check(segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {        
        const joinedURL = `/${segments.join('/')}`;
        const redirectURL = joinedURL === '/sign-out' ? '/' : joinedURL;
        if (this._session.MustChangePassword == true && joinedURL.replace(/^\/+|\/+$/g, '') != this._session.ChangePasswordRoute.replace(/^\/+|\/+$/g, '')) return this._router.navigate([this._session.ChangePasswordRoute]);
        if (this._session.config.site.defaultRoute) return this._router.navigate([this._session.config.site.defaultRoute]);
        else return true;

    }
}
*/